<owc-snackbar hidden></owc-snackbar>

<ng-container *ngIf="isLoggedIn">
  <div id="top-container" class="top-container">
    <owc-select-dropdown 
    #selectDropdownElement1 id="selectDropdownElement1"    class="account-selector-option " size="compact"
    [label]="'label'"
    [limit]="2"
    [placeholder]="'Select view'"
    (selectChange)="selectViewOption($event)"
  >
    <owc-list-item [selected]="!this.centericSelection">Lab view</owc-list-item>
    <owc-list-item [selected]="this.centericSelection">Centric view</owc-list-item>
  </owc-select-dropdown>
    <owc-select-dropdown id="selectDropdownElement" [style.width.px]="250" [multiple]="centericSelection " 
       #selectDropdownElement size="compact" class="account-selector" placeholder="Select account(s)" 
       label="label for select-dropdown" (selectChange)="selectChangeHandler($event)" (panelClose)="panelCloseHandler($event)">
      <ng-container id="licensed-labs" *ngFor="let item of accounts?.['licensed'];index as j">
        <owc-list
          id="licensed-labs-title"
          *ngIf="j == 0"
          style="align-items: center;display: flex;background-color: var(--one-color-interaction-hover-neutral-1);
        height: 2rem"
          size="default"
          i18n="@@licensed-labs"
        >
          Licensed labs
          <!-- <div style="margin-top: 10px;  border:1px solid red">
            <owc-button class="owc-btn"  flat (click)="applyCentricView()" id="centric-btn" style="color: #fff;">Apply</owc-button>
          </div> -->
        </owc-list>
        <owc-list-item id="licensed-lab-item" [icon]="item.icon" *ngIf="!centericSelection" [selected]="this.selectedLabIndex == j">
           <span > {{ item.account_name }} </span>
          <p style="font-size:10px;" id="licensed-lab-serial-no">{{ item.crmNumber }}</p>
        </owc-list-item>

        <!--Following items will shown only in multiple case and if SoC license-->
        <owc-list-item id="licensed-lab-item" [icon]="item.icon" *ngIf="centericSelection && objectKeys(item.license).indexOf('SoC')> -1" [selected]="item.is_lab_selected">
          <owc-checkbox #checkboxElement  [checked]="item.is_lab_selected" style="display: inline-block;position: absolute;"> </owc-checkbox> 
           <span style="margin-left: 24px;"> {{ item.account_name }} </span>
          <p style="font-size:10px;" id="licensed-lab-serial-no">{{ item.crmNumber }}</p>
        </owc-list-item>
       
      </ng-container>
      <ng-container id="non-licensed-labs" *ngFor="let item of accounts?.['non-licensed'];index as i">
      <ng-container *ngIf="!centericSelection">
        <owc-list
          id="non-licensed-labs-title"
          *ngIf="i == 0"
          style="align-items: center;display: flex;background-color: var(--one-color-interaction-hover-neutral-1);
      height: 2rem"
          header="Non licensed labs"
          tabindex="0"
          size="default"
          nested=""
          hydrated=""
          i18n="@@non-licensed-labs"
        >
          Non licensed labs
        </owc-list>

        <owc-list-item id="non-licensed-lab-item" style="background-color: lightgrey;opacity: 0.3;" [icon]="item.icon" [selected]="(i===0 && accounts?.['licensed'].length === 0)">
          {{ item.account_name }}
          <p id="non-licensed-lab-serial-no" style="font-size:10px;">{{ item.crmNumber }}</p>
        </owc-list-item>
      </ng-container>
      </ng-container>
      
    </owc-select-dropdown>
   
  </div>
  <div id="main-page" class="container" style="background: #fff;">
    <owc-tabs #tabsElement id="tabsElement" [(ngModel)]="tabValue" (tabChange)="onTabChange($event.detail)">
      <owc-tab value="overview" class="tab" id="overview-tab"><span i18n="@@overview" id="overview-txt">Overview</span></owc-tab>
      <owc-tab value="devices" [ngClass]="{ 'disable-tab': (licenseExpired === 1  || (socAndCombinedLicense === 0 && this.firewallLicense === 0 &&  this.vulnerabilityLicence === 0)) || (this.centericSelection && this.selectedLabsIndex.length === 0)  }" class="tab" id="devices-tab"><span i18n="@@devices" id="devices-txt">Devices</span></owc-tab>
      <owc-tab value="view-advisories"  [ngClass]="{ 'disable-tab': ((licenseExpired === 1 || (socAndCombinedLicense === 0 && this.advisoriesLicense === 0)) || (this.centericSelection && this.selectedLabsIndex.length === 0) )}" class="tab" id="view-advisories-tab"><span i18n="@@view-advisories" id="advisories-txt">View advisories</span></owc-tab>
    </owc-tabs>
  </div>
</ng-container>
<router-outlet></router-outlet>
<div class="space-4"></div>
<p [style]="{ 'text-align': 'center' }" id="version">v{{ currentApplicationVersion }}</p>
