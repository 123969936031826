import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'charslice'
})
export class CharslicePipe implements PipeTransform {
  public transform(val: string, length: number): string {
    if (val) {
      return val.length > length ? `${val.substring(0, length)} ...` : val;
    }
  }
}
