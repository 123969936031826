import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { Components } from '@one/web-components';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FeatureService } from '../../services/feature.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dl-create-support-ticket',
  templateUrl: './support-ticket.component.html',
  styleUrls: ['./support-ticket.component.scss']
})
export class SupportTicketComponent implements OnInit, OnDestroy {
  public hideBackdrop: boolean;
  public visible: boolean;
  public subs: Subscription[] = [];
  public env: string;
  public showProgressBar: boolean;
  public form: { name: string; email: string; reason: string; description: string; contact: string };

  public constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private featureService: FeatureService) {}
  @ViewChild('modalDialogElement', { static: true }) public modalDialogElement: Components.OwcModalDialog;
  @ViewChild('progressBarElement', { static: true }) public progressBarElement: Components.OwcProgressBar;
  @ViewChild('formElement', { static: true }) public formElement: Components.OwcForm;

  public ngOnInit(): void {
    this.visible = true;
    this.showProgressBar = false;
    this.subs.push(
      this.featureService.env.subscribe(env => {
        this.env = env;
      })
    );

    let desc = this.data.label;
    let deviceName = '';
    let sn = '';

    if (!this.isObjectEmpty(this.data?.details?.deviceDetails)) {
      const deviceDetails = this.data.details.deviceDetails;
      const result = this.processDeviceDetails(deviceDetails);
      deviceName = result.deviceName;
      sn = result.sn;
      desc = this.updateDescription(desc, deviceName, sn);
    }

    this.form = { name: this.data.details?.name, email: this.data.details?.email, reason: '1', description: desc, contact: this.data.contact };
  }

  public processDeviceDetails(deviceDetails: any): { deviceName: string; sn: string } {
    let deviceName = '';
    let sn = '';

    if (this.isObjectEmpty(deviceDetails.value)) {
      sn = deviceDetails?.key ? deviceDetails?.key : '';
    } else {
      deviceName = this.getDeviceName(deviceDetails);
      sn = this.getSerialNumber(deviceDetails);
    }

    return { deviceName, sn };
  }

  public getDeviceName(deviceDetails: any): string {
    let deviceName = deviceDetails.value?.name || '';
    if (deviceDetails.lab) {
      deviceName = deviceDetails.lab?.name || '';
    }
    return deviceName;
  }

  public getSerialNumber(deviceDetails: any): string {
    let sn = deviceDetails.value?.sn || deviceDetails?.key;
    if (deviceDetails.lab) {
      sn = deviceDetails.lab?.serialNo || '';
    }
    return sn;
  }

  public updateDescription(desc: string, deviceName: string, sn: string): string {
    return sn !== '' ? desc + ' for ' + deviceName + ' (' + sn + ')' : desc + ' for ' + deviceName;
  }

  public closePopup(): void {
    this.visible = false;
    this.hideBackdrop = true;
    this.dialog.closeAll();
  }

  public isObjectEmpty(obj: any): any {
    if (obj !== undefined && obj !== null) {
      if (Object.keys(obj).length === 0) {
        return true;
      } else {
        return false;
      }
    }
  }
  public checkReason(): string {
    let reasonText = 'Other';
    if (this.form.reason === '1') {
      reasonText = 'Technical problem';
    } else if (this.form.reason === '2') {
      reasonText = 'Feature request';
    }
    return reasonText;
  }

  public createTicket(): void {
    const ticketForm = {
      name: this.data.details?.name,
      accountId: this.data.details?.accountId,
      temporaryContactName: this.form.email,
      temporaryCallbackNo: this.form.contact,
      materialNo: '',
      contactId: this.data.details?.contactId,
      requestType: 'Software',
      recordType: 'Unqualified',
      channel: 'Mobile',
      priority: 'Medium',
      severity: 'Medium',
      subject: 'Security Suite Support Ticket',
      description: this.form.description,
      reason: this.checkReason()
    };
    console.log('payload to create ticket: ', ticketForm);
    this.submitTicket(ticketForm);
  }

  public submitTicket(ticketForm: any): void {
    if (!ticketForm.description || !ticketForm.reason || !ticketForm.subject || !ticketForm.temporaryCallbackNo || !ticketForm.temporaryContactName) {
      this.featureService.notify({ message: 'Please fill up the missing details', code: '500', status: '', response: {} }, 'warning', true);
    }
    const url = `${this.env}/${this.featureService.msUrl}/${this.data.details.countryCode.toUpperCase()}/${this.data.details.accountId}/support/`;
    this.showProgressBar = true;
    this.subs.push(
      this.featureService.submitTicket(url, ticketForm).subscribe(
        response => {
          if (response?.status === 'SUCCESS') {
            this.featureService.notify(response, 'success');
            this.closePopup();
          } else {
            // code to show message box
            this.featureService.notify(response, 'error', true);
          }
          this.showProgressBar = false;
        },
        error => {
          this.showProgressBar = false;
          console.log('Submit support ticket error: ' + error);
          this.featureService.notify(error?.error ? error.error : error, 'error', true);
        }
      )
    );
  }
  public ngOnDestroy(): void {
    this.showProgressBar = false;
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
