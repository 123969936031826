import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Components } from '@one/web-components';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FeatureService } from '../../../services/feature.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'projects/dialog/src/environments/environment';
import { saveAs } from 'file-saver';
import { AuthService } from '@dialog-eservices-enablement/angular-components';

interface Account {
  account_name: string;
  account_number: string;
  account_primary: string;
  crmNumber: string;
  erpNumber: string;
  sales_org: string;
  license: {
    Firewall: { id: number; expired: boolean; expiry_date: number };
    Vulnerabilities: { id: number; expired: boolean; expiry_date: number };
    expired: boolean;
    SoC: { id: number; expired: boolean; expiry_date: number };
  };
}

@Component({
  selector: 'dl-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit, OnDestroy {
  @ViewChild('modalDialogElement', { static: true }) public modalDialogElement: Components.OwcModalDialog;

  public visible: boolean;
  public hideBackdrop: boolean;
  public subs: Subscription[] = [];
  public env: string;
  public countryCode: string;
  public selectedLab: any;
  public licenseResp: any;
  public marketplaceUrl: string;
  public languageCode = 'en_us';
  public constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private featureService: FeatureService, private activatedRoute: ActivatedRoute, private router: Router, private authService: AuthService) {}
  public ngOnInit(): void {
    this.languageCode = this.authService?.['countryLanguageService']?.['languageCode'] ? this.authService['countryLanguageService']['languageCode'] : 'en_us';
    console.log('languageCode : ', this.languageCode);
    this.hideBackdrop = false;
    this.visible = true;
    if (environment.marketplaceUrl) {
      // this.marketPlaceUrl = environment.marketplaceUrl + '/' + this.countryCode.toLocaleLowerCase() + '/' + this.languageCode + '/products/security-suite';
      this.marketplaceUrl = environment?.marketplaceUrl;
    }
    this.subs.push(
      this.featureService.env.subscribe(env => {
        this.env = env;
      })
    );
    this.subs.push(
      this.featureService.countryCode.subscribe(countryCode => {
        this.countryCode = countryCode;
      })
    );

    this.subs.push(
      this.featureService.selectedLab.pipe(take(1)).subscribe((selAccountData: Account[]) => {
        if (selAccountData && selAccountData.length > 0) {
          this.selectedLab = selAccountData[0];
        }
      })
    );
  }

  public closePopup(): void {
    this.visible = false;
    this.hideBackdrop = true;
    length = this.dialog.openDialogs.length;
    this.dialog.openDialogs[length - 1].close();
    // this.dialog.closeAll();
  }

  public openTermsConditionPopup(license: any): void {
    const data = { title: $localize`:@@terms-and-conditions:Terms and conditions`, declineBtn: $localize`:@@decline-btn:Decline`, acceptConditionBtn: $localize`:@@accept-terms-service-btn:I have read and accept the terms of service`, feature: 'terms', license };
    this.dialog.open(PopupComponent, { data });
  }

  public getLicense(license: string): string {
    if (license === 'firewall-protection') {
      license = 'Firewall';
    } else if (license === 'devices') {
      license = 'Vulnerabilities';
    } else if (license === 'vulnerability-advisories') {
      license = 'Advisories';
    }
    return license;
  }

  public activateTrialLicense(license: any): void {
    const licenseName = this.getLicense(license.id);
    this.callAjax(licenseName);
  }

  public purchaseLicense(license: any): void {
    // const licenseName = this.getLicense(license.id);
    if (this.marketplaceUrl) {
      window.open(this.marketplaceUrl, '_blank');
    }
  }

  public downloadOrGenerateReport(details: any, downloadFlag: boolean): void {
    let url = `${this.env}/${this.featureService.fnUrl}/${this.countryCode}/${details.lab_id}/report/${details?.name}/`;
    if (downloadFlag === true) {
      url = url + `?action=download&report_type=360 Protection Report&hostname=${details?.hostname}`;
    } else {
      url = url + `?action=generate&serial_no=${details.sn}&report_type=360 Protection Report&hostname=${details?.hostname}`;
    }

    this.subs.push(
      this.featureService.downloadOrGenerateReport(url).subscribe(
        response => {
          if (response?.status === 'SUCCESS') {
            this.closePopup();
            if (url.indexOf('download') > -1) {
              const downloadUrl = response?.response?.download_url;
              saveAs(downloadUrl, details?.name + '.pdf');
            } else {
              this.featureService.notify(response, 'success');
              this.redirectTo('app-enabler/laboratory');
            }
          } else {
            this.featureService.notify(response, 'error');
          }
        },
        error => {
          console.log('Error in generating fortinet report: ' + error);
          this.featureService.notify(error?.error ? error.error : error, 'error');
        }
      )
    );
  }
  public redirectTo(uri: string): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([uri]);
    });
  }

  public callAjax(license: string): void {
    const url = `${this.env}/${this.featureService.licenseUrl}/${this.countryCode}/${this.selectedLab.crmNumber}/`;
    const payload = {};
    payload[license] = { enabled: true, expiry_date: '', license_type: '', terms_accepted: true };
    payload['lab_name'] = this.selectedLab.account_name;
    this.subs.push(
      this.featureService.createTrialLicense(url, payload).subscribe(
        response => {
          if (response?.status === 'SUCCESS') {
            this.licenseResp = response;
            this.featureService.notify(response, 'success');
            this.closePopup();
            localStorage.setItem('selectedLab', this.selectedLab.crmNumber);
            window.location.reload();
          } else {
            // code to show message box
            this.featureService.notify(response, 'error', true);
          }
        },
        error => {
          console.log('getVulnerabilityAdvisories error: ' + error);
          this.featureService.notify(error?.error ? error.error : error, 'error', true);
        }
      )
    );
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
