import { Component, Input, OnInit } from '@angular/core';
import Chart from 'chart.js';

@Component({
  selector: 'dl-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements OnInit {
  public chart: any;
  @Input() public firewallPieData: any = [];
  @Input() public devicesPieData: any = [];
  @Input() public devicesChart: boolean;

  public ngOnInit(): void {
    setTimeout(() => {
      if (this.devicesPieData && this.devicesPieData.length > 0) {
        this.createDoughnutChart('devicePieChart', this.devicesPieData);
      }

      if (this.firewallPieData && this.firewallPieData.length > 0) {
        this.createDoughnutChart('firewallPieChart', this.firewallPieData);
      }
    }, 10);
  }

  public createDoughnutChart(elem: string, chartData: any): void {
    this.chart = new Chart(elem, {
      type: 'doughnut', // this denotes tha type of chart

      data: {
        labels: chartData.map(item => item.name),
        datasets: [
          {
            label: 'My First Dataset',
            data: chartData.map(item => item.value),
            backgroundColor: chartData.map(item => item.color),
            hoverOffset: 4
          }
        ]
      },
      options: {
        aspectRatio: 2,
        // responsive: true,
        legend: {
          display: this.devicesPieData.length < 8,
          position: 'right',
          labels: {
            usePointStyle: true
          }
        }
      }
    });
  }
}
