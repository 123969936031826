import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has('Authorization')) {
      // Authorization header is already set, no need to modify the request
      return next.handle(req);
    }

    let bearerToken = 'Bearer ' + localStorage.token;

    const modifiedReq = req.clone({
      setHeaders: {
        Authorization: bearerToken,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    });

    return next.handle(modifiedReq);
  }
}
