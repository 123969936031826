import { APP_INITIALIZER, Inject, Injectable } from '@angular/core';
import { BundleService, ENVIRONMENT, EnvironmentInterface } from '@dialog-eservices-enablement/angular-components';


@Injectable({
  providedIn: 'root'
})
export class AppInitializerService {
  public constructor(
    private bundleService: BundleService,
    @Inject(ENVIRONMENT) private environment: EnvironmentInterface
    ) {}


  public initApp(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.determineAppUrls(); 
      this.bundleService.init().then(resolve).catch(reject);
    });
  }


  private determineAppUrls() {
    const appUrl = window.location.origin;
    this.environment.appUrl = appUrl;
    this.environment.apiUrl = `${appUrl}/apiproxy/api/v1`;
    this.environment.cognito.redirectSignInUrl = `${appUrl}/login`;
    this.environment.cognito.redirectSignOutUrl = `${appUrl}/logout`;
  }
}


export const appInitializerProvider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: (appInitializerService: AppInitializerService) => () => appInitializerService.initApp(),
  deps: [AppInitializerService]
};
