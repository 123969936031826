import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OneSnackBarModule } from '@one/angular-kit/modal';
import { LearnMoreDialogModule, RequestAccessDialogModule, FeatureFlagModule } from '@dialog-eservices-enablement/angular-components';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { CookieService } from 'ngx-cookie-service';
import { HomePageComponent } from './components/home-page/home-page.component';
import { OneWebComponentsAngularModule } from '@one/angular';
import { LabDetailComponent } from './components/lab-detail/lab-detail.component';
import { RouterModule } from '@angular/router';
import { LaboratoryComponent } from './components/laboratory/laboratory.component';
import { CharslicePipe } from './services/charslice.pipe';
import { OverviewComponent } from './components/overview/overview.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './token.interceptor';
import { PopupComponent } from './components/common/popup/popup.component';
import { ViewAdvisoriesComponent } from './components/view-advisories/view-advisories.component';
import { ViewAdvisoriesDetailComponent } from './components/view-advisories-detail/view-advisories-detail.component';
import { SupportTicketComponent } from './components/support-ticket/support-ticket.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DoughnutChartComponent } from './components/doughnut-chart/doughnut-chart.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
@NgModule({
  declarations: [HomePageComponent, LabDetailComponent, LaboratoryComponent, CharslicePipe, OverviewComponent, PopupComponent, ViewAdvisoriesComponent, ViewAdvisoriesDetailComponent, SupportTicketComponent, DoughnutChartComponent, BarChartComponent],
  exports: [],
  imports: [RouterModule, CommonModule, MatCardModule, OneWebComponentsAngularModule, MatIconModule, MatButtonModule, OneSnackBarModule, LearnMoreDialogModule, RequestAccessDialogModule, FeatureFlagModule, BrowserModule, FormsModule, ReactiveFormsModule],
  providers: [CookieService, { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }]
})
export class SampleFeatureModule {}
