import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Components } from '@one/web-components';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FeatureService } from '../../services/feature.service';
import { take, filter } from 'rxjs/operators';
import { AuthService } from '@dialog-eservices-enablement/angular-components';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SupportTicketComponent } from '../support-ticket/support-ticket.component';
import * as $ from 'jquery';

@Component({
  selector: 'dl-lab-detail',
  templateUrl: './lab-detail.component.html',
  styleUrls: ['./lab-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class LabDetailComponent implements OnInit, OnDestroy {
  @ViewChild('iconElement', { static: true }) private iconElement: Components.OwcIcon | undefined;
  @ViewChild('expandableGroupElement', { static: true }) private expandableGroupElements: Components.OwcExpandableGroup;
  @ViewChild('tableElement', { static: true }) public tableElement: Components.OwcTable;

  public id: string;
  public data: any;
  public productData: any;
  public deviceData: {};
  public status: {};
  public deviceStatus: {};
  public env: string;
  public page: number;
  public total: number;
  public rowsOptions: any;
  public rowsPerPage: number;
  public expanded: boolean;
  public expanded2: boolean;
  public noComponentMessage = '';
  public noVulnerabilitiesMessage = '';
  public labId: string;
  public subs: Subscription[] = [];
  public countryCode: string;
  public selectedLab: any;
  public advisoriesLicense: number;
  public socAndCombinedLicense: number;

  @ViewChild('expandableElement', { static: true }) public expandableElementSection: Components.OwcExpandable;
  @ViewChild('paginationElement', { static: true }) public paginationElement: Components.OwcPagination;

  public constructor(private activatedRoute: ActivatedRoute, private featureService: FeatureService, private router: Router, public dialog: MatDialog, private authService: AuthService) {
    this.router.events.pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd)).subscribe(event => {
      if (event.id === 1 && event.url === event.urlAfterRedirects) {
        this.router.navigate(['app-enabler/overview']);
      }
    });
  }

  public pageChangeHandler(event: any): void {
    /* condition added to avoid multiple requests on page load and on next/pre arrow click   */
    if (this.page !== event?.page || this.rowsPerPage !== event?.rowsPerPage) {
      this.getVulnerabilities(event);
    }

    this.page = event?.page;
    this.rowsPerPage = event?.rowsPerPage;
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public ngOnInit(): void {
    this.page = 1;
    this.rowsPerPage = 10;
    this.total = 1;
    this.rowsOptions = [10, 20, 25];
    this.expanded = true;
    this.expanded2 = false;
    this.productData = [];
    this.data = [];
    this.labId = '';
    this.countryCode = '';
    this.selectedLab = {};

    this.subs.push(
      this.featureService.env.subscribe(env => {
        this.env = env;
      })
    );
    this.subs.push(
      this.featureService.selectedLab.pipe(take(1)).subscribe((selAccountData: any) => {
        this.selectedLab = selAccountData[0];
        this.socAndCombinedLicense = this.selectedLab?.license?.expired === false && this.selectedLab?.license?.SoC?.expired === false ? 1 : 0;
        this.advisoriesLicense = this.selectedLab?.license?.expired === false && this.selectedLab?.license?.Advisories?.expired === false ? 1 : 0;
      })
    );
    this.subs.push(
      this.featureService.countryCode.subscribe(countryCode => {
        this.countryCode = countryCode;
      })
    );
    this.subs.push(
      this.featureService.deviceStatus.subscribe(deviceStatus => {
        this.deviceStatus = deviceStatus;
      })
    );
    this.subs.push(
      this.featureService.deviceData.subscribe(deviceData => {
        this.deviceData = deviceData;
        this.labId = deviceData?.['lab']?.labId;
      })
    );

    this.subs.push(
      this.activatedRoute.params.pipe(take(1)).subscribe(params => {
        this.id = params.id;
        /* Get devices/vms products API  */
        this.getSBOMDetails();
        /* Get Vulnerabilities API  */
        this.getVulnerabilities(params);
      })
    );
    this.status = { mainStatus: 'This instrument is safe', subStatus: 'No critical issues detected. One non-urgent action identified', type: 'active' };
  }

  public getSBOMDetails(): void {
    const productsUrl = `${this.env}/${this.featureService.vmsUrl}/${this.countryCode}/${this.labId}/sbom/${this.id}/`;
    this.subs.push(
      this.featureService.getProducts(productsUrl).subscribe(
        response => {
          if (response?.status === 'SUCCESS' || response?.status === 'PARTIAL') {
            this.productData = response?.response?.components;
          } else {
            // code to show message box
            this.noComponentMessage = this.featureService.notify(response, 'error', false);
          }
        },
        error => {
          console.log('getSBOMDetails error: ' + error);
          this.noComponentMessage = this.featureService.notify(error?.error ? error.error : error, 'error', false);
        }
      )
    );
  }

  public getVulnerabilities(event: any): void {
    const url = `${this.env}/${this.featureService.vmsUrl}/${this.countryCode}/${this.labId}/vulnerabilities/${this.id}/`;
    this.subs.push(
      this.featureService.getProductVulnerabilities(url, event).subscribe(
        response => {
          if (response?.status === 'SUCCESS' || response?.status === 'PARTIAL') {
            this.data = response?.response?.data;
            this.total = Math.ceil(response?.response.total / this.rowsPerPage);
            if (this.total === 0) {
              this.total = 1;
            }
          } else {
            // code to show message box
            this.noVulnerabilitiesMessage = this.featureService.notify(response, 'error', false);
          }
        },
        error => {
          console.log('getVulnerabilities error: ' + error);
          this.noVulnerabilitiesMessage = this.featureService.notify(error?.error ? error.error : error, 'error', false);
        }
      )
    );
  }

  public showVulnerabilityDetail(cveid: any, identifier: any): void {
    if ((this.socAndCombinedLicense === 1 || this.advisoriesLicense === 1 ) ||  this.selectedLab.length > 1) {
      let controls;
      controls = $('#tabsElement').find('owc-tab');
      $(controls[2]).trigger('click');
      this.router.navigate(['app-enabler/view-advisories-detail/' + cveid], { queryParams: { identifier } });
    }
  }

  // function to create support ticket
  public createTicket(detail: any, label: string): void {
    const details = { deviceDetails: detail, accountId: this.labId, contactId: this.authService.userProfile.contactId, name: this.authService.userProfile.name, email: this.authService.userProfile.email, countryCode: this.authService.userProfile.countryCode };
    const data = { details, label };
    this.dialog.closeAll();
    this.dialog.open(SupportTicketComponent, { data });
  }
}
