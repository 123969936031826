import { Component, Input, OnInit } from '@angular/core';
import Chart from 'chart.js';

@Component({
  selector: 'dl-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {
  public barChart: any;
  @Input() public vulnerabilityChartData: any = {};

  public ngOnInit(): void {
    setTimeout(() => {
      this.createBarChart();
    }, 0);
  }

  public createBarChart(): void {
    const dates = Object.keys(this.vulnerabilityChartData);
    const counts = dates.map(key => this.vulnerabilityChartData[key]);
    const lables = Object.keys(this.vulnerabilityChartData).map(key => {
      const date = new Date(key);
      return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }).replace(' ', '-');
    });
    this.barChart = new Chart('vulnBarChart', {
      type: 'bar', // this denotes tha type of chart

      data: {
        labels: lables,
        datasets: [
          {
            label: $localize`:@@advisory-tile-vulnerabilities-count:Vulnerabilities Count`,
            data: counts,
            hoverOffset: 4,
            backgroundColor: '#1a75ff'
          }
        ]
      },
      options: {
        layout: {
          responsive: true,
          maintainAspectRatio: 1
        },
        legend: {
          display: false,
          position: 'bottom'
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                color: 'rgb(255,255,250)',
                z: 1
              }
            }
          ]
        }
      }
    });
  }
}
