 <div class="container" [style]="{'margin-top': '2%'}">
    <owc-breadcrumb id="devices-details-breadcrumb" icon="chevron_right" [style]="{'margin-top':'-18px','margin-left': '10%'}">
        <owc-breadcrumb-item routerLink="/app-enabler/laboratory" id="devices-details-breadcrumb-devices" i18n="@@bc-devices">Devices</owc-breadcrumb-item>
        <owc-breadcrumb-item id="devices-details-breadcrumb-deviceName" >{{this.deviceData?.['lab']?.['name']}}</owc-breadcrumb-item>
    </owc-breadcrumb>
</div> 
<div class="container">
    <div [style]="{'width':'10%'}"> </div>
    <owc-card id="devices-details-card-one" flat scroll-shadow [style]="{'width':'76.7%'}">
        <div id="devices-details-card-one-header" slot="header" class="header-section">
            <div class="row">
                <div class="one">
                    <owc-icon class="mat-header-icon" id="device-header-icon" name="security" type="legacy" [style]="{'color':'grey'}"></owc-icon>
                </div>
                <div class="two">
                    <h3 id="devices-details-lab-no"> {{this.deviceData?.['selectedAccountName']}}</h3>
                    <div ><span id="devices-details-device-name" i18n="@@device"> Device</span>: {{this.deviceData?.['lab']?.['name']}}</div>
                    <div ><span id="devices-details-serial-no" i18n="@@serial-no" >Serial No</span>: {{this.deviceData?.['lab']?.['serialNo']}}</div>
                    <div ><span id="devices-details-material-no" i18n="@@material-no">Material No</span>: {{this.deviceData?.['lab']?.['materialNo']}}</div>
                    <div class="space-8"></div>
                    <div class="create-ticket-btn">
                        <button id="devices-details-create-ticket-btn" one-secondary-button mat-raised-button (click)="createTicket(this.deviceData,'')" i18n="@@create-service-ticket">Create service ticket</button>
                    </div>
                </div>
                <div class="three column">
                    <container-element [ngSwitch]="true">
                        <some-element *ngSwitchCase="this.deviceData?.['lab']?.name?.indexOf('Coaguchek') > -1">
                            <span [style]="{'margin-left':'-14px','padding-top':'12px'}"> 
                                <img id="devices-details-device-image" alt="coaguchek " src="assets/coaguchek.png">
                            </span>
                        </some-element>
                       

                        <!-- new image -->
                        <some-element *ngSwitchCase="this.deviceData?.['lab']?.name?.indexOf('Coaguchek') > -1">
                            <span [style]="{'margin-left':'-14px','padding-top':'12px'}">
                                <img id="devices-details-device-image" alt="coaguchek " src="assets/coaguchek.png">
                            </span>
                        </some-element>
                        <some-element *ngSwitchCase="this.deviceData?.['lab']?.name?.indexOf('Liat') > -1">
                            <span [style]="{'margin-left':'-14px','padding-top':'12px'}">
                                <img id="devices-details-device-image" alt="cobas-liat" src="assets/cobas-liat.png">
                            </span>
                        </some-element>

                         <!-- new image -->
                         <some-element *ngSwitchCase="this.deviceData?.['lab']?.name?.indexOf('602') > -1">
                            <span [style]="{'margin-left':'-14px','padding-top':'12px'}">
                                <img id="devices-details-device-image" alt="cobas-e-602-module" src="assets/cps-media-container-cobas-e-602-module.png">
                            </span>
                        </some-element>

                         <!-- new image -->
                         <some-element *ngSwitchCase="this.deviceData?.['lab']?.name?.indexOf('Data Manager') > -1">
                            <span [style]="{'margin-left':'-14px','padding-top':'12px'}">
                                <img id="devices-details-device-image"  alt="Cobas data manager" src="assets/Cobas_Data_manager.jpg">
                            </span>
                        </some-element>

                         <!-- new image -->
                         <some-element *ngSwitchCase="this.deviceData?.['lab']?.name?.indexOf('ISE Module') > -1">
                            <span [style]="{'margin-left':'-14px','padding-top':'12px'}">
                                <img id="devices-details-device-image" alt="Cobas 8000" src="assets/cobas-8000-ise-module.png">
                            </span>
                        </some-element>

                         <!-- new image -->
                         <some-element *ngSwitchCase="this.deviceData?.['lab']?.name?.indexOf('701') > -1">
                            <span [style]="{'margin-left':'-14px','padding-top':'12px'}">
                                <img alt="Cobas 701" id="devices-details-device-image" src="assets/C-701.png">
                            </span>
                        </some-element>

                         <!-- new image -->
                         <some-element *ngSwitchCase="this.deviceData?.['lab']?.name?.indexOf('801') > -1">
                            <span [style]="{'margin-left':'-14px','padding-top':'12px'}">
                                <img alt="Cobas 801 e" id="devices-details-device-image" src="assets/C_801e.jpeg">
                            </span>
                        </some-element>

                         <!-- new image -->
                         <some-element *ngSwitchCase="this.deviceData?.['lab']?.name?.indexOf('702') > -1">
                            <span [style]="{'margin-left':'-14px','padding-top':'12px'}">
                                <img id="devices-details-device-image" alt="Cobas 702" src="assets/C702.png">
                            </span>
                        </some-element>

                         <!-- new image -->
                         <some-element *ngSwitchCase="this.deviceData?.['lab']?.name?.indexOf('cobas pro') > -1">
                            <span [style]="{'margin-left':'-14px','padding-top':'12px'}">
                                <img id="devices-details-device-image" alt="Cobas Pro-analytical unit" src="assets/C_Pro_analytical_unit.jfif">
                            </span>
                        </some-element>

                         <!-- new image -->
                         <some-element *ngSwitchCase="this.deviceData?.['lab']?.name?.indexOf('503') > -1">
                            <span [style]="{'margin-left':'-14px','padding-top':'12px'}">
                                <img id="devices-details-device-image" src="assets/Cobasc503.png" alt="Cobas 503">
                            </span>
                        </some-element>

                         <!-- new image -->
                         <some-element *ngSwitchCase="this.deviceData?.['lab']?.name?.indexOf('471') > -1">
                            <span [style]="{'margin-left':'-14px','padding-top':'12px'}">
                                <img id="devices-details-device-image" alt="Cobas P471" src="assets/C_p471.png">
                            </span>
                        </some-element>

                         <!-- new image -->
                         <some-element *ngSwitchCase="this.deviceData?.['lab']?.name?.indexOf('501') > -1">
                            <span [style]="{'margin-left':'-14px','padding-top':'12px'}">
                                <img id="devices-details-device-image" alt="Cobas P501" src="assets/cobas-p-501.png">
                            </span>
                        </some-element>

                        <some-element *ngSwitchCase="this.deviceData?.['lab']?.name?.indexOf('711') > -1">
                            <span [style]="{'margin-left':'-14px','padding-top':'12px'}">
                                <img id="devices-details-device-image" alt="Cobas T 711" src="assets/cobas-t-711.png">
                            </span>
                        </some-element>

                        

                        <some-element *ngSwitchCase="this.deviceData?.['lab']?.name?.indexOf('6800') > -1">
                            <span [style]="{'margin-left':'-14px','padding-top':'12px'}">
                                <img id="devices-details-device-image" alt="Cobas 6800" src="assets/cobas6800.png">
                            </span>
                        </some-element>
                        <some-other-element
                            *ngSwitchCase="this.deviceData?.['lab']?.name?.indexOf('pulse') > -1">
                            <span [style]="{'margin-left':'-14px','padding-top':'12px'}">
                                <img id="devices-details-device-image" alt="Cobas pulse" src="assets/cobas-pulse.png">
                            </span>
                        </some-other-element>

                        <!--default case when there are no matches -->
                        <some-element *ngSwitchDefault>
                            <span [style]="{'margin-left':'-14px','padding-top':'12px'}"> 
                                <img id="devices-details-device-image" alt="Cobas 8000" src="assets/Cobas-8000.png">
                            </span>
                        </some-element>
            </container-element>
                </div>
            </div>
        </div>
        <div [style]="{
          background: 'var(--one-color-disabled-background)',
          display: 'flex',
          'flex-direction': 'column',
          margin: 'auto',
          'margin-top':'20px'
        }">

        </div>
    </owc-card>
    <div [style]="{'width':'10%'}"> </div>
</div>
<div class="space-24"></div>
<div class="container">
    <div [style]="{'width':'10%'}"> </div>

    <owc-expandable-group [style]="{'width':'79%'}" #expandableGroupElements >
        <owc-expandable id="devices-details-sbom-card" variant="standard" round expanded #expandableElement [(ngModel)]="expanded">
            <span id="devices-details-sbom-title" slot="title" i18n="@@sbom">Software bill of material</span>
            <span slot="content" style="padding:0;width: 99.7%;margin-left: 2px;">
                <div class="flex-container">
                    <div style="flex-grow: 1; margin-right: 0; padding-bottom: 12px;" class="flex-child">
                        <owc-table id="devices-details-sbom-table" [height]="'auto'">
                            <owc-table-header id="devices-details-sbom-table-header" sticky >
                              <owc-table-header-cell id="devices-details-sbom-header-cell" width="auto" i18n="@@component">Component</owc-table-header-cell>
                            </owc-table-header>
                            <owc-table-body id="devices-details-sbom-table-body" [style]="{'width': '100%','overflow-y': 'auto', 'max-height': '500px', 'overflow-x': 'hidden' }">
                              <owc-table-row  id="devices-details-sbom-row" *ngFor="let row of productData">
                                <owc-table-cell id="devices-details-sbom-row-cell-component">{{row.name}}</owc-table-cell>
                              </owc-table-row>
                              <ng-container *ngIf="productData.length ==0">
                                <div style="width:100%">
                                    <div id="no-component-found" *ngIf="!noComponentMessage" style="float: left;width: 85%;text-align: center; margin-top: 2%;padding-bottom: 15px;" i18n="@@no-component-found">No component found</div>
                                    <div *ngIf="noComponentMessage" style="float: left;width: 85%;text-align: center; margin-top: 2%;">
                                        <div style="display: inline-flex;">
                                            <span id="devices-details-sbom-error" style="padding-bottom: 15px;">{{noComponentMessage}}</span>
                                        </div>
                                    </div>
                                </div>
                              </ng-container>
                            </owc-table-body>
                    </owc-table>
                    </div>
                    
                  </div>
                
            </span>
            <owc-button slot="control" flat
                disable-ripple
                round="round"
                disabled="disabled"
                [style.margin-right.px]="6"
                [style.--content-padding]="'0'"
                [style.min-width.px]="36"
                [style.min-height.px]="36"
                id="devices-details-sbom-control-btn"
                
            >
            <owc-icon id="control-btn-icon" [name]="expanded ? 'caret_up' : 'caret_down'"  [style.cursor]="'pointer'" type="filled"></owc-icon>
          </owc-button>
        </owc-expandable>

         <owc-expandable id="devices-details-vulnerabilities-card" variant="standard" round expanded #expandableElement [(ngModel)]="expanded2">
            <span id="devices-details-vulnerabilities-title" slot="title" i18n="@@vulnerabilities">Vulnerabilities</span>
            <span slot="content" style="padding:0;width: 99.7%;margin-left: 2px;">
                <div class="flex-container">
                    <div style="flex-grow: 1; margin-right: 0; padding-bottom: 12px;" class="flex-child">
                        <owc-table id="devices-details-vulnerabilities-table" #tableElement  [height]="'auto'">
                            <owc-table-header sticky id="devices-details-vulnerabilities-table-header">
                              <owc-table-header-cell id="devices-details-vulnerabilities-table-header-column" width="15%" i18n="@@cve-id">CVE id</owc-table-header-cell>
                              <owc-table-header-cell id="devices-details-vulnerabilities-table-header-column" width="70%" i18n="@@description">Description</owc-table-header-cell>
                              <owc-table-header-cell id="devices-details-vulnerabilities-table-header-column" width="15%" i18n="@@identified-on">Identified on</owc-table-header-cell>
                            </owc-table-header>
                            <owc-table-body id="devices-details-vulnerabilities-table-body" [style]="{'width': '100%','overflow-y': 'auto', 'max-height': '500px', 'overflow-x': 'hidden' }">
                                <span *ngFor="let row of data">
                                    <owc-table-row id="devices-details-vulnerabilities-table-row" (click)="showVulnerabilityDetail(row.cve, row.identifier)">
                                        <owc-table-cell id="devices-details-vulnerabilities-table-row-cell">{{row.cve}}</owc-table-cell>
                                        <owc-table-cell id="devices-details-vulnerabilities-table-row-cell" title="{{row.Vulnerability}}">{{row.Vulnerability| charslice : 84}}</owc-table-cell>
                                        <owc-table-cell id="devices-details-vulnerabilities-table-row-cell">{{row.Identified_On}}</owc-table-cell>
                                    </owc-table-row>
                                </span>
                              <ng-container *ngIf="data.length ==0">
                                <div style="width:100%">
                                    <div id="no-vulnerabilities-found" *ngIf="!noVulnerabilitiesMessage" style="float: left;width: 85%;text-align: center; margin-top: 2%;" i18n="@@no-vulnerabilities-found">No vulnerabilities found</div>
                                    <div *ngIf="noVulnerabilitiesMessage" style="float: left;width: 85%;text-align: center; margin-top: 2%;">
                                        <div style="display: inline-flex;">
                                            <span id="devices-details-vulnerabilities-error" style="padding-bottom: 15px;">{{noVulnerabilitiesMessage}}</span>
                                        </div>
                                    </div>
                                </div>
                              </ng-container>
                            </owc-table-body>
                            <div id="devices-details-vulnerabilities-footer" slot="footer">
                                <owc-pagination id="devices-details-vulnerabilities-pagination" [options]="rowsOptions" [page]="page" [rowsPerPage]="rowsPerPage"  [total]="total" (pageChange)="pageChangeHandler($event.detail)">
                                    <div id="pagination-rows-count" slot="rows-per-page" i18n="@@rows-per-page"> Rows per page </div>
                                    <div id="pagination-page-no"> {{page}} <span id="of-txt" i18n="@@of"> of </span> {{total}} </div>
                                  </owc-pagination>
                            </div>
                          </owc-table>
                    </div>  
                  </div>  
            </span>
            <owc-button slot="control" flat
            disable-ripple
            round="round"
            disabled="disabled"
            [style.margin-right.px]="6"
            [style.--content-padding]="'0'"
            [style.min-width.px]="36"
            [style.min-height.px]="36"
            id="devices-details-vulnerabilities-control-btn"
        >
        <owc-icon id="control-btn-icon" [name]="expanded2 ? 'caret_up' : 'caret_down'"  [style.cursor]="'pointer'" type="filled"></owc-icon>
      </owc-button>
        </owc-expandable> 
    </owc-expandable-group>
    <div [style]="{'width':'10%'}"> </div>
</div>
<div class="space-48"></div>