<div class="container" [style]="{ 'margin-top': '2%' }">
  <owc-breadcrumb id="vulnerability-details-breadcrumb" icon="chevron_right" [style]="{ 'margin-top': '-18px', 'margin-left': '6%' }">
    <owc-breadcrumb-item routerLink="/app-enabler/view-advisories" id="vulnerability-details-breadcrumb-devices" i18n="@@vulnerabiliries-advisories">Vulnerability advisories</owc-breadcrumb-item>
    <owc-breadcrumb-item id="vulnerability-details-breadcrumb-deviceName">{{ this.id }}</owc-breadcrumb-item>
  </owc-breadcrumb>
</div>
<div class="container">
  <div [style]="{ width: '5%' }"></div>
  <div class="row w-90">
    <div [style]="{ 'margin-top': '10px', 'margin-left': '1%' }">
      <h2 id="cve-id">{{ this.id }}</h2>
    </div>
    <div *ngIf="this.responseError ==='' && this?.vulnerabilityData" class="flex-row">
      <div class="col-9">
        <div class="flex-row">
          <div class="col-12">
            <owc-card flat scroll-shadow>
              <div slot="header">
                <div style="font-weight:500" id="vulnerability-detail-description-text" i18n="@@advisory">Advisory</div>
              </div>
              <div id="vulnerability-detail-description" class="desc">{{((vulnerabilityData?.description?.text) ? (vulnerabilityData?.description?.text): (vulnerabilityData?.description?.impact))}}</div>
            </owc-card>
          </div>
        </div>
        <div>
          <h3 id="known-affected-products-text" i18n="@@known-affected-products" style="margin-bottom: 12px;">Known affected products</h3>
          <owc-table [height]="'auto'" style="box-shadow: none;">
            <owc-table-header sticky >
              <owc-table-header-cell width="30%" i18n="@@products-txt">Products</owc-table-header-cell>
              <owc-table-header-cell width="40%" i18n="@@components-txt">Components</owc-table-header-cell>
              <owc-table-header-cell width="30%" i18n="@@estimated-product-risk">Estimated product risk</owc-table-header-cell>
            </owc-table-header>
            <owc-table-body class="affected-product-table">
              <div *ngIf="(!this.vulnerabilityData?.exploitation_risk_level || this.vulnerabilityData?.exploitation_risk_level.length===0)" class="affected-product-table-error-msg" >
                <h4 id="no-affected-product-found-text" i18n="@@no-affected-product-found">No affected product found</h4>
              </div>
              <owc-table-row *ngFor="let row of this.vulnerabilityData?.exploitation_risk_level">
                <owc-table-cell >{{ row.product_name }}</owc-table-cell>
                <div *ngIf="row.component_name!=='' && row.risk_level!=='';then productTable else productTableMessage"></div>
                <ng-template #productTable >
                  <owc-table-cell >{{ row.component_name }}</owc-table-cell>
                  <owc-table-cell [title]="getToolTip(row.risk_level)" >{{ (row.risk_level =='na'? 'Not applicable':row.risk_level)| titlecase }}</owc-table-cell>
                </ng-template>
                <ng-template #productTableMessage>
                  <p class="affected-product-table-row-msg" i18n="@@vulnerability-not-assessed">The vulnerability has not been assessed for this product.</p>
                </ng-template>
              </owc-table-row>
            </owc-table-body>
          </owc-table>
        </div>
        <div *ngIf="(this.vulnerabilityData?.description &&  this.vulnerabilityData?.description.mitigation || (!this.vulnerabilityData?.affected_products || this.vulnerabilityData?.affected_products.length===0) && !this.loading)">
          <h3 id="remediation-advice-text" i18n="@@remediation-advice" class="mb-12">Remediation advice</h3>
          <div *ngIf="(!this.vulnerabilityData?.affected_products || this.vulnerabilityData?.affected_products.length===0)" style="line-height: 50px; text-align: center; background-color: #DBEFC5;" >
            <h3 id="mitigation-not-required-text" i18n="@@mitigation-not-required">Mitigation not required as no affected product found</h3>
          </div>

          <div *ngIf="(this.vulnerabilityData?.description &&  this.vulnerabilityData?.description.mitigation )" >
            <p id="mitigation-text" style="padding-left: 12px;">{{ this.vulnerabilityData?.description.mitigation }}</p>
          </div>
        </div>
        <div>
          <h3 id="references-text" i18n="@@references" class="mb-0">References</h3>
          <div style="margin-left: 12px;" *ngFor="let reference of this.vulnerabilityData?.references">
            <a href="{{ reference }}">{{ reference }}</a>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="flex-row">
          <div class="col-1"></div>
          <div class="col-11">
            <owc-card disabled flat scroll-shadow>
              <div slot="header">
                <div style="color: black; font-weight:500;font-size: large;" id="quick-info-text" i18n="@@quick-info">Quick info</div>
              </div>
              <div>
                <div *ngIf="vulnerabilityData?.basescore">
                  <p style="color: black; margin-bottom: 1px;" id="severity-text" i18n="@@severity">Severity</p>
                  <owc-badge id="severity-badge" style="margin-top: 0px;" #badgeElement class="severity-badge-element">
                    <span id="basescore">{{ vulnerabilityData?.basescore }}</span>
                  </owc-badge>
                </div>
                <div *ngIf="vulnerabilityData?.advisory_date">
                  <p style="color: black; margin-bottom: 1px;" id="published-date-text" i18n="@@published-date">Published date</p>
                  <p style="margin-top: 0px;" id="published-date">{{ this.featureService.getFormattedDate( vulnerabilityData?.advisory_date) }}</p>
                </div>
                <div *ngIf="vulnerabilityData?.entry_created">
                  <p style="color: black; margin-bottom: 1px;" id="known-since-text" i18n="@@known-since">Known since</p>
                  <p style="margin-top: 0px;" id="known-since-date">{{ this.featureService.getFormattedDate(vulnerabilityData?.entry_created)}}</p>
                </div>
                <div *ngIf="vulnerabilityData?.entry_modified">
                  <p style="color: black; margin-bottom: 1px;" id="last-modified-text" i18n="@@last-modified">Last modified</p>
                  <p style="margin-top: 0px;" id="last-modified-date">{{ this.featureService.getFormattedDate(vulnerabilityData?.entry_modified) }}</p>
                </div>
                <div *ngIf="vulnerabilityData?.vendor">
                  <p style="color: black; margin-bottom: 1px;" id="vendor-text" i18n="@@advisories-vendor">Vendor</p>
                  <p style="margin-top: 0px;" id="vender-name">{{ vulnerabilityData?.vendor }}</p>
                </div>
              </div>
            </owc-card>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="this.responseError !='' ">
      <p style="margin-top:3%;text-align: center;font-size: large;"><owc-icon name="alarm" type="outlined" style="color: orange; margin-right: 4px;vertical-align: middle;"></owc-icon>{{ this.responseError }}</p>
    </div>
    <div [style]="{ width: '5%' }"></div>
  </div>
</div>
