<owc-modal-dialog #modalDialogElement width="50%"  [(ngModel)]="visible" dense-content hide-backdrop >
	<div slot="header" [style]="{display: 'flex', alignItems: 'center' }" id="popup-header">
		{{data.title}}
	</div>
	<div  *ngIf="data.feature != 'terms'">
		<p *ngIf="data.feature === 'report-download'"  id="popup-main-text">{{data.body}}</p> 
		<p  *ngIf="data.feature === 'license'" id="popup-main-text">
			<span >
				<span>{{data.body}}</span>
				<span i18n="@@trail-license-duration-msg-1">&nbsp;Activate a trail license for </span>
				<span i18n="@@trail-license-duration-msg-2" style="font-weight: bold;">&nbsp;60 days &nbsp; </span>
				<span i18n="@@trail-license-duration-msg-3">or purchase the license.</span>
			</span>
		</p> 
		<p *ngIf="data.extraNote"  id="popup-main-text">
			{{data.extraNote}}
		</p>
	</div>
	<div class="terms-body"  *ngIf="data.feature === 'terms'">
		<div class="content-div">
			<p i18n="@@terms-condition-para1">The scope of the Security Suite ("Web App") is to allow lab managers & IT Security to assess security threats, prepare for audits, and conduct maintenance by providing vulnerability & network monitoring of instruments registered in the Roche Laboratory Network.
			</p> 
			<p i18n="@@terms-condition-para2">The Web App is owned by Roche Diagnostics International Ltd ("Roche"). Your use of the Web App is governed by these Trial Terms and Conditions ("Terms"). You can only use the Web App in agreement with the following Terms, so read them carefully before using the Web App.
			</p>
			<p style="margin-bottom:0px"><span >
				<span i18n="@@terms-condition-para31">Roche grants You a worldwide, non-exclusive, non-transferable, temporary right to use and access the Web App, and all related systems, platforms or networks up to the installed capacity as determined by Roche for 
				</span>
				<span i18n="@@terms-condition-para32" style="font-weight: bold;">&nbsp;sixty (60) calendar days&nbsp;</span>
				<span i18n="@@terms-condition-para33">from the Effective Date, unless otherwise provided by Roche ("Trial Period"), only for the purposes of evaluating internally and determining whether to acquire a license to the Web App for a fee.
				</span>
				</span></p>
			<p style="margin:0px;"><span i18n="@@terms-condition-para4">Please find here some important facts about the Web App</span>
			</p>
			<ul style="margin-top:0px">
				<li i18n="@@terms-condition-para5">The Web App is designed to be used by the laboratories employees with a navify Portal account.</li>
			</ul>
			

			<h4 i18n="@@terms-condition-head1">Disclaimer of warranties and limitation of liability</h4>
			<p i18n="@@terms-condition-para6">THE USE OF THE WEB APP IS AT YOUR OWN RISK. THE WEB APP IS PROVIDED ON AN "AS-IS" AND "AS- AVAILABLE" BASIS WITHOUT ANY EXPRESS OR IMPLIED REPRESENTATIONS OR WARRANTIES OF ANY KIND, INCLUDING ANY REPRESENTATIONS OR WARRANTIES REGARDING ACCURACY, OPERABILITY, USE, TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, AND ROCHE AND ITS AFFILIATES HEREBY DISCLAIM ALL SUCH REPRESENTATIONS AND WARRANTIES. WITHOUT LIMITING THE FOREGOING, ROCHE AND ITS AFFILIATES MAKE NO REPRESENTATION OR WARRANTY, EXPRESS OR IMPLIED, THAT THE WEB APP OR ITS USE WILL BE SECURE, UNINTERRUPTED OR ERROR-FREE. IN NO EVENT SHALL ROCHE OR ANY OF ITS AFFILIATES BE DEEMED TO BE ENGAGED, DIRECTLY OR INDIRECTLY, IN THE PRACTICE OF MEDICINE OR THE DISPENSING OF MEDICAL SERVICES. UNDER NO CIRCUMSTANCES, AS A RESULT OF YOUR USE OF THE WEB APP, WILL ROCHE BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES OR ANY OTHER DAMAGES WHATSOEVER, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ACCESS TO OR THE USE OF THE WEB APP.
			</p>

			<h4 i18n="@@terms-condition-head2">Intellectual Property Rights</h4>
			<p style="margin-bottom: 0px;"><span i18n="@@terms-condition-para7">Except for the limited license and use rights expressly granted to you under these Terms, all title to and rights in the Web App, including any and all technology, software and content, including ownership rights to all patents, copyrights, trademarks, service marks, trade dress, trade secrets and other technology, including any derivatives of and all goodwill associated with the foregoing, are the exclusive property of Roche and/or its affiliates.
			</span></p>
			<p style="margin-top: 0px;"><span i18n="@@terms-condition-para8">Any feedback, comments, ideas, improvements or suggestions (collectively, "Suggestions") provided by you to Roche with respect to the Web App shall be the sole and exclusive property of Roche. Roche shall be free to use, copy, modify, publish, or redistribute the Suggestions for any purpose and in any way without any credit or any compensation to you.
			</span></p>

			<h4 i18n="@@terms-condition-head3">Updates and Termination</h4>
			<p style="margin-bottom: 0px;"><span i18n="@@terms-condition-para9">Roche may from time to time, in its sole discretion, develop and provide updates for the Web App, which may include upgrades, bug fixes and other error corrections and/or new features. Updates may also modify or delete in their entirety certain features and functionality.  
			</span></p>
			<p style="margin-top: 0px;"><span i18n="@@terms-condition-para10">Roche or any other third party appointed by Roche may terminate or modify your access to the Web App with or without notice to you, at any time for any reason.
			</span></p>

			<h4 i18n="@@terms-condition-head4">Acceptance of the these Terms</h4>
			<p style="margin-bottom:0px; margin-top:0px;" i18n="@@terms-condition-para11">By accessing and using the Web App, you affirm that:</p>
			<ul style="margin-top:0px">
				<li i18n="@@terms-condition-list1">You have a navify Portal account;</li>
				<li i18n="@@terms-condition-list2">You will comply with these Terms.</li>
			</ul>
		</div>
	</div>
	<div slot="actions" class="actions"  *ngIf="data.feature === 'license'">
		<div (click)="closePopup()" class="primary-close-btn"><span style="cursor: pointer;">{{data.primaryBtn}}</span></div>
		<owc-button class="owc-btn" variant = "secondary"   [disabled]=" data.license.licenseType && data.license.expired "  flat (click)="openTermsConditionPopup(data.license)" id="activate-trial-license-btn">{{data.activateTrialLicenseBtn}}</owc-button>
		<owc-button class="owc-btn"  flat (click)="purchaseLicense(data.license)" id="purchase-license-btn">{{data.purchaseLicenseBtn}}</owc-button>
	</div>

	<div slot="actions"  class="actions"  *ngIf="data.feature === 'report-download'">
		<owc-button class="owc-btn" variant = "secondary"  flat (click)="closePopup()" id="close-btn">{{data.closeBtn}}</owc-button>
		<owc-button class="owc-btn" variant = "secondary" [disabled]=" ((data.details.reportDetails?.status === 'pending' || data.details.reportDetails?.status === 'running') &&  (data.details.reportDetails?.duration)/60 <= 5)"
		 flat (click)="downloadOrGenerateReport(data.details, false)" id="generate-report-btn">{{data.generateReportBtn}}</owc-button>
		<owc-button class="owc-btn" variant = "primary"  [disabled]=" !data.details.reportDetails "  flat (click)="downloadOrGenerateReport(data.details, true)" id="download-report-btn">{{data.downloadBtn}}</owc-button>
		
	</div>
	<div slot="actions" class="actions"  *ngIf="data.feature === 'terms'">
		<owc-button variant = "secondary" class="decline-btn" style="margin: 0 auto 0 0;" flat (click)="closePopup()" id="decline-btn">{{data.declineBtn}}</owc-button>
		<owc-button class="owc-btn"  flat (click)="activateTrialLicense(data.license)" id="purchase-license-btn">{{data.acceptConditionBtn}}</owc-button>
	</div>
</owc-modal-dialog> 
