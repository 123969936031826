<ng-container *ngIf="isLoggedIn">
  <div class="container"></div>
  <div class="space-8"></div>

  <div class="container">
    <div [style]="{ width: '5%' }"></div>

    <owc-expandable-group [style]="{ width: '89%' }" #expandableGroupElement>
      <owc-expandable id="devices-firewall-card" variant="standard" *ngIf="( selectedLabIds.length > 1 ) || ( socLicence === 1 || firewallLicense === 1 )" round expanded #expandableElement [(ngModel)]="firewallExpanded">
        <span slot="title"  id="devices-firewall-title" i18n="@@firewall-status"> Firewall status</span>
        <span slot="content" style="padding:0">
          <div class="flex-container">
            <div style="flex-grow: 1; margin-right: 0; padding-bottom: 12px;" class="flex-child">
              <owc-table [height]="'auto'" id="devices-firewall-table">
                <owc-table-header sticky>
                  <owc-table-header-cell width="auto" id="devices-firewall-table-lab-name" i18n="@@lab-name" *ngIf="selectedLabIds.length > 1" >Lab name</owc-table-header-cell>
                  <owc-table-header-cell id="devices-firewall-table-column-firewall-name" width="auto" i18n="@@firewall-name"  >Firewall name</owc-table-header-cell>
                  <owc-table-header-cell id="devices-firewall-table-column-serial-no" width="auto" i18n="@@serial-no" >Serial no</owc-table-header-cell>
                  <owc-table-header-cell id="devices-firewall-table-column-configuration-status" width="auto" i18n="@@configuration-status" >Configuration status</owc-table-header-cell>
                  <owc-table-header-cell id="devices-firewall-table-column-connection-status" width="auto" i18n="@@connection-status" >Connection status</owc-table-header-cell>
                  <owc-table-header-cell id="devices-firewall-table-column-action" width="auto" i18n="@@action" >Action</owc-table-header-cell>
                </owc-table-header>
                <owc-table-body [style]="{'width': '100%','overflow-y': 'auto', 'max-height': '500px', 'overflow-x': 'hidden' }">
                  <ng-container *ngFor="let row of this.firewallData | keyvalue">
                    <owc-table-row id="devices-firewall-table-row" *ngIf="row?.value?.['name'] ">
                      <owc-table-cell id="devices-firewall-table-row-labname" *ngIf="selectedLabIds.length > 1">{{ multiLabNameObj[row?.value?.lab_id]?.labName }}</owc-table-cell>
                        
                      <owc-table-cell id="devices-firewall-table-row-firewall-name" title="{{ row?.value.name }}">{{ row?.value?.name | charslice: 30 }}</owc-table-cell>
                      <owc-table-cell id="devices-firewall-table-row-serial-no">{{ row?.value?.sn }}</owc-table-cell>
                      <owc-table-cell id="devices-firewall-table-row-configuration-status" *ngIf="row?.value?.conf_status === 'insync'">
                        <div class="col-5" > 
                          <owc-chip  round outlined  style="width: 105px;">
                            <owc-icon class="col-3  chip-icon chip-icon-green"  style="width: 16px;margin-top: 0px;" id="icon-configured">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><path fill="green" fill-rule="evenodd" d="M20 7.42l-11 11L7.58 17 3 12.42l1.42-1.41L9 15.59 18.58 6 20 7.42z" clip-rule="evenodd"/></svg>
                            </owc-icon>
                              <span class="col-9 chip-icon-txt chip-icon-green font-weight-500" >Configured</span>
                            </owc-chip>
                          </div>
                      </owc-table-cell>
                      <owc-table-cell id="devices-firewall-table-row-configuration-status" *ngIf="row?.value?.conf_status !== 'insync'">
                        <div class="col-6" >  
                          <owc-chip  style="width: 130px;" round outlined class="chip-outlined chip-outlined-grey"> 
                            <owc-icon class="col-3 chip-icon"  style="width: 16px;margin-top: 0px;" id="icon-not-configured">
                              <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="m19.474 5.436-1.409-1.41L11.5 10.59 4.911 4 3.5 5.41 10.09 12 3.5 18.59 4.911 20l6.589-6.59L18.09 20l1.41-1.41L12.911 12l6.563-6.564Z" fill="#000"/></svg>
                            </owc-icon>
                            <span class="col-9 chip-icon-txt font-weight-500" >Not configured</span>
                          </owc-chip>
                      </div>
                      </owc-table-cell>
                    
                      <owc-table-cell id="devices-firewall-table-row-connection-status" *ngIf="row?.value?.conn_status === 'up'">
                        <div class="col-5">
                          <owc-chip  round outlined style="width: 100px;">
                              <owc-icon class="col-3  chip-icon chip-icon-green" id="icon-connected" style="width: 16px;margin-top: 0px;">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><path fill="green" d="M21.4 5.4l-2.8-2.8c-.8-.8-2-.8-2.8 0l-2.9 2.9 1.4 1.4L17.2 4 20 6.8l-7.4 7.4-1.4-1.4-1.4 1.4 1.4 1.4c.8.8 2 .8 2.8 0l7.4-7.4c.8-.7.8-2 0-2.8z"/><path fill="green" d="M2.6 18.6l2.8 2.8c.8.8 2 .8 2.8 0l2.9-2.9-1.4-1.4L6.8 20 4 17.2l7.5-7.5 1.4 1.4 1.4-1.4-1.4-1.4c-.8-.8-2-.8-2.8 0l-7.5 7.5c-.8.8-.8 2 0 2.8z"/></svg>
                              </owc-icon>
                                <span class="col-9 chip-icon-txt chip-icon-green font-weight-500" >Connected</span>
                            </owc-chip>
                        </div>
                        </owc-table-cell>
                      
                      <owc-table-cell id="devices-firewall-table-row-connection-status" *ngIf="row?.value?.conn_status !== 'up'">
                        <div class="col-6" >
                          <owc-chip  style="width: 125px;"  round outlined class=" chip-outlined chip-outlined-grey"> 
                            <owc-icon class="col-3 chip-icon"  id="icon-not-connected" style="width: 16px;margin-top: 0px;">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><path fill="#000" d="M7.781 6.124L6.367 7.538l3.889 3.889 1.414-1.414-3.889-3.89zm6.765 6.645l-1.415 1.414 3.89 3.889 1.414-1.414-3.89-3.89zM12.2 6.6l4-4c.8-.8 2-.8 2.8 0l2.8 2.8c.8.8.8 2 0 2.8l-4 4-1.4-1.4 4-4-2.8-2.7-4 4-1.4-1.5zm.4 11l-3.9 3.9c-.8.8-2 .8-2.8 0l-2.8-2.8c-.8-.8-.8-2 0-2.8L7 12l1.4 1.4-4 3.9 2.8 2.8 3.9-3.9 1.5 1.4z"/></svg>
                            </owc-icon>
                            <span class="col-9 chip-icon-txt font-weight-500" >Not connected</span>
                          </owc-chip>
                        </div>
                      </owc-table-cell>  
                      <owc-table-cell  id="devices-firewall-table-row-action">
                        <div>
                          <owc-hyperlink  id="devices-firewall-table-action-download-report" (click)="openPopup({ name: row?.value?.name, sn: row?.value?.sn, reportDetails: row?.value?.report_status, hostname: row?.value?.hostname, lab_id:  row?.value?.lab_id })" [style]="{ 'margin-right': '10px' }">
                            <owc-icon *ngIf="(!row?.value?.report_status) || (row?.value?.report_status?.status == 'generated' || row?.value?.report_status?.status == 'failed')" id="download-report-icon" name="download" type="outlined"   title="{{downloadReportTitle}}">
                            </owc-icon>
                            <span style="margin-left: -6px;" *ngIf="row?.value?.report_status?.status == 'pending' || row?.value?.report_status?.status == 'running'">
                              <img id="devices-firewall-table-download-icon" alt=" Report in progress" src="assets/Download-report-regenerating.svg">
                            </span>
                          </owc-hyperlink>

                          <owc-hyperlink *ngIf="row?.value.conf_status == 'insync' && row?.value.conn_status == 'up'; else notuptodate"> </owc-hyperlink>
                          <ng-template #notuptodate>
                            <owc-hyperlink id="devices-firewall-table-action-create-ticket" (click)="createTicket(row, this.checkConnectivity)" style="word-break: break-word" title="{{checkConnectivity}}">
                              <owc-icon id="create-ticket-icon" name="call_support" type="outlined"></owc-icon>
                            </owc-hyperlink>
                          </ng-template>
                        </div>
                        <div id="devices-firewall-table-action-show-details" *ngIf="socLicence === 1" class="two" (click)="prepareToShowDetails(row?.value,'')" [style]="{ display: 'grid', 'justify-content': 'end' }">
                          <owc-icon id="nevigate-icon" name="chevron_right" type="outlined" [style]="{ cursor: 'pointer' }" class="icon-variant-null icon-type-outlined icon-variant-default" variant="default" hydrated=""></owc-icon>
                        </div>
                      </owc-table-cell>
                    </owc-table-row>

                     <!-- Start to show HA-Backup device if any --> 
                     <ng-container *ngIf="row?.value?.ha_slave && row?.value?.ha_slave.length > 0 ">
                      <ng-container *ngFor="let subRow of row.value?.ha_slave;let i = index ">
                        <owc-table-row id="devices-firewall-table-row" *ngIf=" row.value?.ha_slave[i]?.role === 'slave'" style="opacity: 0.7;"> 
                          <owc-table-cell id="devices-firewall-table-row-labname" *ngIf="selectedLabIds.length > 1">{{ multiLabNameObj[row?.value?.lab_id]?.labName }}</owc-table-cell>
                       
                          <owc-table-cell id="devices-firewall-table-row-firewall-name" title="{{  row.value?.ha_slave[i]?.name }}">{{  row.value?.ha_slave[i]?.name | charslice: 30 }}&nbsp;(HA-Backup)</owc-table-cell>
                          <owc-table-cell id="devices-firewall-table-row-serial-no">{{  row.value?.ha_slave[i]?.sn }}</owc-table-cell>
                          <owc-table-cell id="devices-firewall-table-row-configuration-status">&nbsp;&nbsp; &nbsp;</owc-table-cell>
                          <owc-table-cell style="opacity: 0.7;" id="devices-firewall-table-row-connection-status" *ngIf="row.value?.ha_slave[i]?.status === 1">
                            <div class="col-5" >
                              <owc-chip  round outlined  style="width: 105px;" >
                                <owc-icon class="col-3  chip-icon chip-icon-green"  style="width: 16px;margin-top: 0px;" id="icon-backup-connected">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><path fill="green" d="M21.4 5.4l-2.8-2.8c-.8-.8-2-.8-2.8 0l-2.9 2.9 1.4 1.4L17.2 4 20 6.8l-7.4 7.4-1.4-1.4-1.4 1.4 1.4 1.4c.8.8 2 .8 2.8 0l7.4-7.4c.8-.7.8-2 0-2.8z"/><path fill="green" d="M2.6 18.6l2.8 2.8c.8.8 2 .8 2.8 0l2.9-2.9-1.4-1.4L6.8 20 4 17.2l7.5-7.5 1.4 1.4 1.4-1.4-1.4-1.4c-.8-.8-2-.8-2.8 0l-7.5 7.5c-.8.8-.8 2 0 2.8z"/></svg>
                                </owc-icon>
                                  <span class="col-9 chip-icon-txt chip-icon-green font-weight-500" >Connected</span>
                              </owc-chip>
                            </div>
                          </owc-table-cell>
                          <owc-table-cell style="opacity: 0.7;" id="devices-firewall-table-row-connection-status" *ngIf="row.value?.ha_slave[i]?.status !== 1">
                            <div class="col-6" >
                              <owc-chip   round outlined class=" chip-outlined chip-outlined-grey" style="width: 125px;"> 
                                <owc-icon class="col-3 chip-icon"  style="width: 16px;margin-top: 0px;" id="icon-backup-not-connected">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><path fill="#000" d="M7.781 6.124L6.367 7.538l3.889 3.889 1.414-1.414-3.889-3.89zm6.765 6.645l-1.415 1.414 3.89 3.889 1.414-1.414-3.89-3.89zM12.2 6.6l4-4c.8-.8 2-.8 2.8 0l2.8 2.8c.8.8.8 2 0 2.8l-4 4-1.4-1.4 4-4-2.8-2.7-4 4-1.4-1.5zm.4 11l-3.9 3.9c-.8.8-2 .8-2.8 0l-2.8-2.8c-.8-.8-.8-2 0-2.8L7 12l1.4 1.4-4 3.9 2.8 2.8 3.9-3.9 1.5 1.4z"/></svg>
                                </owc-icon>
                                <span class="col-9 chip-icon-txt font-weight-500" >Not connected</span>
                              </owc-chip>
                            </div>
                          </owc-table-cell>
                          
                          
                          <owc-table-cell  id="devices-firewall-table-row-action">
                            <div id="devices-firewall-table-action-show-details" class="two" *ngIf="socLicence === 1" (click)="prepareToShowDetails(row?.value, row.value?.ha_slave[i]?.sn)" [style]="{ display: 'grid', 'justify-content': 'end' }">
                              <owc-icon id="nevigate-icon" name="chevron_right" type="outlined" [style]="{ cursor: 'pointer' }" class="icon-variant-null icon-type-outlined icon-variant-default" variant="default" hydrated=""></owc-icon>
                            </div>
                          </owc-table-cell>
                        </owc-table-row>
                      </ng-container>
                    </ng-container>
                    <!-- End show ha Backup device --> 

                    <owc-table-row id="devices-firewall-table-row" *ngIf=" !row?.value?.['name']">
                      <owc-table-cell id="devices-firewall-table-row-labname" *ngIf="selectedLabIds.length > 1">
                        {{ multiLabNameObj[row?.value?.lab_id]?.labName }}
                      </owc-table-cell>
                       
                      <owc-table-cell id="devices-firewall-table-row-firewall-name">
                        <div title="{{ row?.key }}">{{ row?.key | charslice: 30 }}</div></owc-table-cell
                      >
                      <owc-table-cell id="devices-firewall-table-row-serial-no" style="width: 300%;z-index:1;">
                        <div id="firewall-not-found" i18n="@@firewall-not-found">
                          <owc-icon name="circle_remove" family="filled" class="col-3 chip-icon"  style="font-family: 'One icons Filled', sans-serif; color: orange;"></owc-icon>
                          <span class="col-9 chip-icon-txt" >
                            Firewall was not found at the backend management
                          </span>
                        </div>
                      </owc-table-cell>
                      <owc-table-cell id="devices-firewall-table-row-configuration-status"></owc-table-cell>
                      <owc-table-cell id="devices-firewall-table-row-connection-status"></owc-table-cell>
                      <owc-table-cell id="devices-firewall-table-row-action">
                        <div>
                          <span class="" (click)="createTicket(row, this.registerFirewall)">
                            <owc-hyperlink id="devices-firewall-table-action-create-ticket" *ngIf="row?.key"  title="{{registerFirewall}}"> <owc-icon id="create-ticket-icon" name="call_support" type="outlined"></owc-icon></owc-hyperlink>
                          </span>
                        </div>
                        <div id="devices-firewall-table-action-show-details" class="two" *ngIf="socLicence === 1" (click)="prepareToShowDetails(row?.value,'')" [style]="{ display: 'grid', 'justify-content': 'end' }">
                          <owc-icon id="nevigate-icon" name="chevron_right" type="outlined" [style]="{ cursor: 'pointer' }" class="icon-variant-null icon-type-outlined icon-variant-default" variant="default" hydrated=""></owc-icon>
                        </div>
                      </owc-table-cell>
                    </owc-table-row>
                   
                  </ng-container>
                  <ng-container *ngIf="isObjectEmpty()">
                    <div style="width:100%">
                      <div id="no-firewall-detected" style="float: left;width: 85%;text-align: center; margin-top: 2%;" i18n="@@no-firewall-detected">No firewall detected</div>
                      <div style="margin-top: 1.5%;position: absolute;right: 12%;">
                        <span  (click)="createTicket({}, this.requestFirewall)">
                          <owc-hyperlink id="devices-firewall-table-action-create-ticket" title="{{requestFirewall}}" style="position: absolute;right: 3%;margin-top: 1.5%;"> <owc-icon class="create-ticket-icon" name="call_support" type="outlined"></owc-icon></owc-hyperlink>
                        </span>
                      </div>
                    </div>
                  </ng-container>
                </owc-table-body>
                <div slot="footer"></div>
              </owc-table>
            </div>
          </div>
        </span>
        <owc-button id="devices-firewall-control-btn" slot="control" flat disable-ripple round="round" disabled="disabled" [style.margin-right.px]="6" [style.--content-padding]="'0'" [style.min-width.px]="36" [style.min-height.px]="36">
          <owc-icon id="control-btn-icon" [name]="firewallExpanded ? 'caret_up' : 'caret_down'" [style.cursor]="'pointer'" type="filled"></owc-icon>
        </owc-button>
      </owc-expandable>

      <owc-expandable id="devices-allDevices-card" variant="standard" *ngIf="( selectedLabIds.length > 1 ) || ( socLicence === 1 || vulnerabilityLicence === 1 )" round [value]="devicesExpanded" #expandableElement [(ngModel)]="devicesExpanded">
        <span slot="title" id="devices-allDevices-title" [style]="{ width: '75%' }" i18n="@@all-devices">All devices</span>
        <owc-hyperlink id="devices-allDevices-show-hide-btn" *ngIf="!this.showHideView && !this.editVersion" style="width: 25%; text-align: end;
        float: right;" slot="title"  class="edit-device-link" (click)="devicesExpanded=true; $event.stopPropagation();">
          <owc-icon-button type="outlined" id="menu-anchor" class="more-vertical" flat icon="more_vertical" (click)="toggleMenuVisibility()"></owc-icon-button>
          <owc-menu anchor="menu-anchor" limit="2" id="owc-menu" alignment="right" >
            <owc-list-item secondary-text="Edit version" (click)="editDevices($event)" i18n="@@edit-version">Edit version</owc-list-item>
            <owc-list-item secondary-text="Show/Hide" (click)="showHideDevices($event)" i18n="@@show-hide">Show/Hide</owc-list-item>
          </owc-menu>
        </owc-hyperlink>
        
        <span slot="content" style="padding:0">
          <div class="flex-container">
            <div style="flex-grow: 1; margin-right: 0; padding-bottom: 12px;" class="flex-child">
              <owc-table id="devices-allDevices-table" [height]="'auto'">
                <owc-table-header id="devices-allDevices-table-header" sticky >
                  <owc-table-header-cell width="auto" id="devices-allDevices-name" i18n="@@lab-name" *ngIf="selectedLabIds.length > 1">Lab name</owc-table-header-cell>
                  <owc-table-header-cell width="auto" id="devices-allDevices-name" i18n="@@device-name">Device name</owc-table-header-cell>
                  <owc-table-header-cell width="auto" id="devices-allDevices-instrumentLine" i18n="@@instrument-line">Instrument line</owc-table-header-cell>
                  <owc-table-header-cell width="auto" id="devices-allDevices-serianNo" i18n="@@serial-no">Serial no</owc-table-header-cell>
                  <owc-table-header-cell width="auto" id="devices-allDevices-version" i18n="@@software-version"> Version</owc-table-header-cell>
               
                  <owc-table-header-cell width="auto" id="devices-allDevices-action" *ngIf="!this.showHideView && !this.editVersion" i18n="@@default-device-action-header">Action</owc-table-header-cell>
                  <owc-table-header-cell width="auto" id="devices-allDevices-visibility" *ngIf="this.showHideView" i18n="@@edit-device-action-header">Visibility</owc-table-header-cell>
                </owc-table-header>
                <owc-table-body id="devices-allDevices-table-body" [style]="{'width': '100%','overflow-y': 'auto', 'max-height': '500px', 'overflow-x': 'hidden' }">
                  
                    <ng-container *ngFor="let lab of paginatedDevices; let idx = index" >
                      <owc-table-row id="devices-allDevices-table-row" *ngIf="((!this.editVersion || !this.showHideView) && lab.isVisible) || ( this.showHideView) ">
                        <owc-table-cell id="devices-allDevices-table-row-labname" *ngIf="selectedLabIds.length > 1">{{ multiLabNameObj[lab?.labId]?.labName }}</owc-table-cell>
                        <owc-table-cell id="devices-allDevices-table-row-labname">{{ lab?.name }}</owc-table-cell>
                        <owc-table-cell id="devices-allDevices-table-row-instrumentLine">{{ lab?.instrumentFamily | charslice: 24 }}</owc-table-cell>
                        <owc-table-cell id="devices-allDevices-table-row-serialNo">{{ lab?.serialNo }}</owc-table-cell>
                        <owc-table-cell id="devices-allDevices-table-row-version" *ngIf=" !this.editVersion && lab?.softwareVersion ">&nbsp;{{ lab?.softwareVersion }}</owc-table-cell>
                        <owc-table-cell id="devices-allDevices-table-row-version" *ngIf="!this.editVersion && (!lab?.softwareVersion )">
                          <p class="version-empty">- &nbsp;&nbsp;</p>
                        </owc-table-cell>
                        <!-- Edit version --> 
                        <owc-table-cell id="devices-allDevices-table-row-visibility" *ngIf="this.editVersion">
                          <owc-input  compact="true"  (click)="onValueChange($event.detail, lab)" style="width: 90%;" placeholder="Enter version" type="text" variant="outlined" [(ngModel)]="lab.softwareVersion"></owc-input>
                        </owc-table-cell>

                        <owc-table-cell id="devices-allDevices-table-row-action" *ngIf="!this.showHideView && !this.editVersion">
                          <div class="col-11" >
                            <owc-hyperlink  *ngIf="lab?.softwareVersion" id="devices-alldevices-table-action-create-ticket" (click)="createTicket({lab:lab}, updateVersion+' ('+lab?.softwareVersion+')')" style="word-break: break-word" title="{{updateVersion}}">
                              <owc-icon id="create-ticket-icon" name="call_support" type="outlined"></owc-icon>
                            </owc-hyperlink>
                          </div>
                          <div class="col-1">
                            <div id="devices-allDevices-table-row-nevigate" class="two" (click)="showMoreDetails(lab)" [style]="{ display: 'grid', 'justify-content': 'end' }">
                              <owc-icon id="nevigate-icon" name="chevron_right" type="outlined" [style]="{ cursor: 'pointer' }" class="icon-variant-null icon-type-outlined icon-variant-default" variant="default" hydrated=""></owc-icon>
                            </div>
                          </div>
                        </owc-table-cell>

                       
                        

                        <!-- Show/ hide icon -->
                        <owc-table-cell id="devices-allDevices-table-row-visibility" *ngIf="this.showHideView">
                          <div class="two" [style]="{ display: 'grid', 'justify-content': 'flex-start' }">
                            <owc-slide-toggle id="show-hide-toggle-btn" [(ngModel)]="lab.isVisible" [value]="lab.isVisible.toString()" (click)="onValueChange($event.detail, lab)"></owc-slide-toggle>
                          </div>
                        </owc-table-cell>
                      </owc-table-row>
                    </ng-container>
                  
                  <ng-container *ngIf="paginatedDevices.length == 0 && this.loading == false ">
                    <div style="width:100%">
                      <div id="no-device-found" style="float: left;width: 85%;text-align: center; margin-top: 2%;" i18n="@@no-device-found">No devices found</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="paginatedDevices.length > 0 && allDevicesHidden && !this.showHideView">
                    <div style="width:100%">
                      <div id="device-found-but-hidden" style="float: left;width: 85%;text-align: center; margin-top: 2%;" i18n="@@device-found-but-hidden">Devices found but they are hidden</div>
                    </div>
                  </ng-container>
                </owc-table-body>

                

                <div  id="devices-allDevices-footer" slot="footer">
                  <owc-icon id="element-hide-icon" name="element_hide" type="filled" *ngIf="!this.showHideView && paginatedDevices.length > 0 && invisibleCount > 0"></owc-icon>&nbsp;
                  <span id="devices-allDevices-footer-devices-hidden-count" *ngIf="!this.showHideView && paginatedDevices.length > 0 && invisibleCount > 0" style="padding-top: 4px; width: 40%;">{{ invisibleCount }} <span i18n="@@devices-hidden-counter">device(s) hidden</span></span>

                  <owc-pagination id="devices-allDevices-footer-pagination" [options]="rowsOptions" [page]="page" [rowsPerPage]="rowsPerPage"  [total]="total" (pageChange)="pageChangeHandler($event.detail)">
                      <div id="pagination-rows-count" slot="rows-per-page" i18n="@@rows-per-page"> Rows per page </div>
                      <div id="pagination-page-no"> {{page}} <span id="of-txt" i18n="@@of"> of </span> {{total}} </div>
                  </owc-pagination>
                  <div [style]="{ display: 'flex' ,'width': '40%', 'justify-content': 'end'}" *ngIf="(this.showHideView || this.editVersion)  && paginatedDevices.length > 0">
                    <owc-button id="devices-allDevices-footer-cancel-btn" [disabled]="isDisabled" flat variant="secondary" (click)="cancelUpdateDevices()" i18n="@@cancel-btn">Cancel</owc-button>
                    <owc-button id="devices-allDevices-footer-update-btn" [disabled]="isDisabled || this.updatedDeviceDetails.length === 0" flat (click)="updateDevices()" [style]="{ 'margin-left': '16px' }" i18n="@@update-btn">Update</owc-button>
                  </div>



                </div>
              </owc-table>
            </div>
          </div>
        </span>
        <owc-button id="devices-allDevices-control-btn" slot="control" flat disable-ripple round="round" disabled="disabled" [style.margin-right.px]="6" [style.--content-padding]="'0'" [style.min-width.px]="36" [style.min-height.px]="36">
          <owc-icon id="devices-allDevices-control-icon" [name]="devicesExpanded ? 'caret_up' : 'caret_down'" [style.cursor]="'pointer'" type="filled"></owc-icon>
        </owc-button>
      </owc-expandable> 
    </owc-expandable-group>
    <div [style]="{ width: '5%' }"></div>
  </div>
  <div class="space-48"></div>
</ng-container>
