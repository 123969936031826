
<owc-modal-dialog #modalDialogElement width="50%"  [(ngModel)]="visible" dense-content>
  <owc-progress-bar #progressBarElement [value]="50" *ngIf="showProgressBar" ></owc-progress-bar>
  <div slot="header" [style]="{ display: 'flex', alignItems: 'center' }" id="support-ticket-popup-header">
    Raise a ticket
  </div>
  <owc-form  [style]="{ display: 'flex', padding: '0 24px' }">
    <div class="flex-row">
        <div class="col-12 mb-3">
          <label for="name"> Name</label>
          <owc-input id="name"   name="name" class="w-100" required [(ngModel)]="form.name"   disabled  #name="ngModel" type="text" ngDefaultControl></owc-input>
          <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert">
              <div *ngIf="name.errors?.['required']" class="error">Name is required.</div>  
          </div>
        </div>

        <div class="col-12 mb-3">
          <label for="email">Email</label>
          <owc-input id="email" class="w-100 " [(ngModel)]="form.email" required type="email" name="email" ngDefaultControl  #email="ngModel" email disabled ></owc-input>
          <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert">
            <div *ngIf="email.errors?.['required']" class="error">Email is required.</div>  
          </div>
        </div>
  
        <div class="col-12 mb-3">
            <label for="contact"> Contact</label>
            <owc-input id="contact" name="contact"  required class="w-100" ngDefaultControl  [(ngModel)]="form.contact" type="tel" 
            pattern="^\+?(?:\d\s?){9,12}(?:\d\s?){0,2}$"
            #contact="ngModel" placeholder="+49 691 234 5678"></owc-input>
            <div *ngIf="contact.invalid && (contact.dirty || contact.touched)" class="alert">
              <div *ngIf="contact.errors?.['required'] " class="error">Contact is required.</div> 
              <div  class="error" *ngIf=" contact.errors.pattern ">Invaild contact</div> 
            </div>
        </div>
      
        <div class="col-12 mb-3">
            <label for="name"> Type of request</label>
            <owc-radio-group name="owc-radio-group" value="1" [(ngModel)]="form.reason" class="w-100"  style=" display: flex;" ngDefaultControl >
                <owc-radio value="1" >Technical problem</owc-radio>
                <owc-radio value="2">Feature request</owc-radio>
                <owc-radio value="3">Other</owc-radio>
            </owc-radio-group>
        </div>
        <div class="col-12 mb-3" >
            <label for="Description">Description</label>
            <owc-textarea rows="5" name="description" required [(ngModel)]="form.description" variant="filled" placeholder="Description" #description="ngModel" ngDefaultControl ></owc-textarea>
            <div *ngIf="description.invalid && (description.dirty || description.touched)" class="alert">
              <div *ngIf="description.errors?.['required']" class="error">Description is required.</div>  
            </div>
          </div>
      </div>
   
  </owc-form>

  <div slot="actions" [style]="{ display: 'flex', justifyContent: 'flex-end', padding: '10px 12px' }">
    <owc-button variant="secondary" [style]="{ 'margin-left': '12px' }" flat (click)="closePopup()" id="support-ticket-close-btn">Cancel</owc-button>
    <owc-button [style]="{ 'margin-left': '12px' }" flat  id="support-ticket-submit-btn"
    [disabled]="!form.description || !form.reason || !form.email  || ( !form.contact || contact.invalid ) || !form.name"
    (click)="createTicket()">Submit</owc-button>
  </div>
</owc-modal-dialog>
