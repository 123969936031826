import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FeatureService } from '../../services/feature.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

interface Account {
  account_name: string;
  account_number: string;
  account_primary: string;
  crmNumber: string;
  erpNumber: string;
  sales_org: string;
  license: {
    Firewall: { id: number; expired: boolean; expiry_date: number };
    Vulnerabilities: { id: number; expired: boolean; expiry_date: number };
    expired: boolean;
    Advisories: { id: number; expired: boolean; expiry_date: number };
    SoC: { id: number; expired: boolean; expiry_date: number };
  };
}

@Component({
  selector: 'dl-view-advisories-detail',
  templateUrl: './view-advisories-detail.component.html',
  styleUrls: ['./view-advisories-detail.component.scss']
})
export class ViewAdvisoriesDetailComponent implements OnInit {
  public id: string;
  public vulnerabilityData: any;
  public subs: Subscription[] = [];
  public env: string;
  public countryCode: string;
  public selectedLab: any;
  public responseError = '';
  private identifier: string;
  public loading: boolean;

  public ngOnInit(): void {
    this.loading = true;
    this.countryCode = '';
    this.vulnerabilityData = {};
    this.subs.push(
      this.featureService.env.subscribe(env => {
        this.env = env;
      })
    );
    this.subs.push(
      this.featureService.countryCode.subscribe(countryCode => {
        this.countryCode = countryCode;
      })
    );
    this.subs.push(
      this.featureService.selectedLab.pipe(take(1)).subscribe((selAccountData: Account[]) => {
        if (selAccountData && selAccountData.length > 0) {
          this.selectedLab = selAccountData[0];
        }
      })
    );
    this.activatedRoute.params.pipe(take(1)).subscribe(params => {
      this.id = params.id;
    });
    this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
      this.identifier = params?.identifier ? params?.identifier : '';
    });

    this.getVulnerabilityDetails();
  }
  public constructor(private activatedRoute: ActivatedRoute, private featureService: FeatureService) {}

  public getToolTip(riskLevel: string): string {
    switch (riskLevel) {
      case 'acceptable':
        return $localize`:@@view-advisory-acceptable-tooltip:Acceptable based on existing mitigation measures.`;
      case 'mitigated':
        return $localize`:@@view-advisory-mitigated-tooltip:Mitigated by existing mitigation measures.`;
      case 'na':
        return $localize`:@@view-advisory-na-tooltip:Not applicable`;
      case 'Not applicable':
        return $localize`:@@view-advisory-na-tooltip:Not applicable`;
      default:
        return $localize`:@@view-advisory-default-tooltip:Preliminary estimation`;
    }
  }

  public getVulnerabilityDetails(): void {
    const url = `${this.env}/${this.featureService.vmsUrl}/${this.countryCode}/${this.selectedLab.crmNumber}/vulnerability/${this.identifier}/deatils/`;

    this.subs.push(
      this.featureService.getVulnerabilityAdvisorieDetail(url).subscribe(
        response => {
          if (response?.status === 'SUCCESS' || response?.status === 'PARTIAL') {
            this.vulnerabilityData = response?.response?.data;
            this.responseError = '';
          } else {
            // code to show message box
            this.responseError = this.featureService.notify(response, 'error', false);
            console.log(this.responseError);
          }
          this.loading = false;
        },
        error => {
          console.log('getVulnerabilityDeatil error: ' + error);
          this.responseError = this.featureService.notify(error?.error, 'error', false);
          this.loading = false;
        }
      )
    );
  }
}
