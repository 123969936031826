<div class="container">
  <div [style]="{ width: '5%' }"></div>
  <div [style]="{ width: '90%' }">
    <div slot="header" class="header-section">
      <div class="flex-row">
        <div class="col-8">
          <h3 id="vulnerability-advisories-text" i18n="@@vulnerabiliries-advisories">Vulnerability advisories</h3>
        </div>
        <div class="col-4">
          <div class="flex-row" style="justify-content: end;">
            <p><span id="sort-by-text" i18n="@@sort-by">Sort by</span>:&nbsp;&nbsp;</p>
            <owc-select-dropdown id="sort-by-dropdown" #selectDropdownElement #sortByEl [limit]="5" outlined size="compact" style="margin-top: 10px;" (panelClose)="sortByValue(sortByEl.value)">
              <owc-list-item id="published-date-option" value="publish_date" i18n="@@published-date" [selected]="sortBy.includes('publish_date')">Published date</owc-list-item>
              <owc-list-item id="seviarty-option" value="basescore" i18n="@@severity" [selected]="sortBy.includes('basescore')">Severity</owc-list-item>
            </owc-select-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div [style]="{ width: '5%' }"></div>
</div>

<div class="container">
  <div [style]="{ width: '5%' }"></div>
  <div class="row w-90">
    <div class="sub-container">
      <div class="left-container">
        <div class="row w-90 ">
          <div class="search-section bg-white">
            <div class="inner-search-section">
              <p style="margin-bottom: 0" id="search-text" i18n="@@search-btn">Search</p>
              <owc-input id="view-advisories-filter-input" compact [style]="{ width: '100%' }" placeholder="Search" round="false" variant="outlined" [(ngModel)]="searchTxt" (keyup.enter)="Search()"> </owc-input>
              <owc-button id="view-advisories-filter-btn" flat (click)="Search()" [style]="{ 'margin-top': '18px', float: 'right' }" i18n="@@search-btn">Search</owc-button>
            </div>
          </div>
          <div class="space-12"></div>
          <div class="search-section bg-white">
            <div class="inner-search-section">
              <p style="margin-bottom: 0" id="advisories-filters-text" i18n="@@advisories-filters">Filters</p>
              <owc-radio-group id="redio-grup-filter-type" style="display: flex" name="filters" [(ngModel)]="redioFilterSelected" (inputChange)="onRadioInputChange($event.detail)">
                <owc-radio value="basic" id="basic" i18n="@@basic">Basic</owc-radio>
                <owc-radio value="advanced" id="advanced" i18n="@@advanced">Advanced</owc-radio>
              </owc-radio-group>
              <div [hidden]="!(this.redioFilterSelected === 'basic')">
                <p style="margin-bottom: 10px" id="advisories-cve-publish-date" i18n="@@advisories-cve-publish-date">CVE publish date</p>
                <owc-select-dropdown id="basic-filter-dropdown" style="width:100%" #selectDropdownElement size="compact" name="publish-date" outlined (selectChange)="selectChangeHandler($event)">
                  <owc-list-item id="dropdown-one-week-option" [selected]="selectedDays === 0">Last 7 days</owc-list-item>
                  <owc-list-item id="dropdown-two-weeks-option" [selected]="selectedDays === 1">Last 15 days</owc-list-item>
                  <owc-list-item id="dropdown-one-month-option" [selected]="selectedDays === 2">Last 1 month</owc-list-item>
                  <owc-list-item id="dropdown-three-month-option" [selected]="selectedDays === 3">Last 3 months</owc-list-item>
                  <owc-list-item id="dropdown-six-month-option" [selected]="selectedDays === 4">Last 6 months</owc-list-item>
                </owc-select-dropdown>
                <owc-button id="view-advisories-filter-btn" variant="secondary" (click)="applyFilter()" [style]="{ 'margin-top': '18px', float: 'right' }" i18n="@@filter-btn">Filter</owc-button>
              </div>
              <div [hidden]="!(this.redioFilterSelected === 'advanced')">
                <div>
                  <p style="margin-bottom: 10px" id="advisories-category-type-text" i18n="@@advisories-category-type">Category type</p>
                  <owc-select-dropdown id="category-type-dropdown" #selectDropdownElement #categoryType style="width:100%;" multiple outlined [placeholder]="'--Select--'" [size]="'compact'" searchable [limit]="4" (panelClose)="onCategoryPanelClose(categoryType.value)">
                    <owc-list-item id="category-type-option" *ngFor="let option of advanceFilterOption?.categories" value="{{ option.name }}" [selected]="selectedCategories.includes(option.name)"
                      ><owc-checkbox>{{ option.name }}</owc-checkbox></owc-list-item
                    >
                  </owc-select-dropdown>
                </div>
                <div>
                  <p style="margin-bottom: 10px" id="advisories-vendor-text" i18n="@@advisories-vendor">Vendor</p>
                  <owc-select-dropdown id="advisories-vendor-dropdown" #selectDropdownElement #vendor style="width:100%;" (panelClose)="onVendorPanelClose(vendor.value)" multiple outlined [placeholder]="'--Select--'" [size]="'compact'" [limit]="4">
                    <owc-list-item id="advisories-vendor-dropdown-option" *ngFor="let option of advanceFilterOption?.vendors" value="{{ option.name }}" [selected]="selectedVendor.includes(option.name)"
                      ><owc-checkbox>{{ option.name }}</owc-checkbox></owc-list-item
                    >
                  </owc-select-dropdown>
                </div>
                <div>
                  <p style="margin-bottom: 10px" id="advisories-product-text" i18n="@@advisories-product">Product</p>
                  <owc-select-dropdown id="advisories-product-dropdown" #selectDropdownElement #product style="width:100%;" (panelClose)="onProductPanelClose(product.value)" multiple outlined [placeholder]="'--Select--'" [size]="'compact'" [limit]="4">
                    <owc-list-item id="advisories-product-dropdown-option" *ngFor="let option of advanceFilterOption?.products" value="{{ option.name }}" [selected]="selectedProduct.includes(option.name)"
                      ><owc-checkbox>{{ option.name }}</owc-checkbox></owc-list-item
                    >>
                  </owc-select-dropdown>
                </div>
                <div>
                  <p style="margin-bottom: 10px" id="advisories-severity" i18n="@@severity">Severity</p>
                  <owc-select-dropdown id="advisories-severity-dropdown" #selectDropdownElement #severity style="width:100%;" (selectChange)="selectChangeSeverityHandler($event)" outlined [placeholder]="'--Select--'" [size]="'compact'" [limit]="5">
                    <owc-list-item id="advisories-severity-dropdown-option" [selected]="selectedSeverity === 0">All</owc-list-item>
                    <owc-list-item id="advisories-severity-dropdown-option" [selected]="selectedSeverity === 1">0.1-3.9</owc-list-item>
                    <owc-list-item id="advisories-severity-dropdown-option" [selected]="selectedSeverity === 2">4-6.9</owc-list-item>
                    <owc-list-item id="advisories-severity-dropdown-option" [selected]="selectedSeverity === 3">7-8.9</owc-list-item>
                    <owc-list-item id="advisories-severity-dropdown-option" [selected]="selectedSeverity === 4">9-10</owc-list-item>
                  </owc-select-dropdown>
                </div>
                <div>
                  <p style="margin-bottom: 10px" id="advisories-published-date" i18n="@@published-date">Published date</p>
                  <owc-datepicker id="advisories-published-date-picker" #datepickerElement #publishedDate auto-close quick style="width:100%;" [maxDate]="this.today.getTime()" outlined (panelOpen)="onDatePickerPanelOpen($event)" (valueChange)="onPublishedDateValueChange($event)" compact format="dd MMM yyyy" [variant]="'outlined'" [label]="'Published date range'" [placeholder]="datePickerPlaceholder" [type]="'range'"> </owc-datepicker>
                </div>
                <div>
                  <p style="margin-bottom: 10px" id="advisories-last-modified-date" i18n="@@last-modified-date">Last modified date</p>
                  <owc-datepicker id="advisories-last-modified-datepicker" #datepickerElement #modifiedDate auto-close quick style="width:100%;" outlined [maxDate]="this.today.getTime()" (panelOpen)="onDatePickerPanelOpen($event)" (valueChange)="onModifiedDateValueChange($event)" compact format="dd MMM yyyy" [variant]="'outlined'" [label]="'Last modified date range'" [placeholder]="datePickerPlaceholder" [type]="'range'"> </owc-datepicker>
                </div>
                <div class="flex-row">
                  <div class="col-6" (click)="clearFilters()" style="color: #0b41cd; margin-top: 26px; font-weight: 500;"><span style="cursor: pointer;" i18n="@@clear-filters">Clear filters</span></div>
                  <div class="col-6">
                    <owc-button id="view-advisories-filter-btn" variant="secondary" (click)="applyAdvanceFilter()" [style]="{ 'margin-top': '18px', float: 'right' }" i18n="@@filter-btn">Filter</owc-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-container">
        <ng-container *ngIf="advisoryData.length === 0">
          <div style="margin: 0 auto;">
            <div *ngIf="searchTxt.length > 0" style="text-align: center;font-weight: 500;">
              <p>{{ searchTxt }} <span id="advisories-not-found-text" i18n="@@advisories-not-found-txt">not found</span></p>
            </div>
            <div *ngIf="searchTxt.length === 0" style="text-align: center;font-weight: 500;">
              <p id="advisories-no-vulnerability-found-text" i18n="@@advisories-no-vulnerability-found-txt">No Vulnerability found</p>
            </div>
            <div *ngIf="searchTxt.length > 0">
              <p id="view-advisories-search-default-text" i18n="@@view-advisories-search-default-txt">please change CVE publish date or find CVE record by keyword</p>
            </div>
          </div>
        </ng-container>
        <ng-container *ngFor="let d of advisoryData; let i = index">
          <owc-card id="view-advisories-feature-card-{{ i }}" flat scroll-shadow class="lower-card">
            <div slot="header" class="header-section">
              <div class="row">
                <div id="view-advisories-feature-date" class="publish-date">
                  <p id="view-advisories-published-date" ><span i18n="@@view-advisories-published-date">Published on</span> : {{ d?.description?.date | date: 'd MMMM  y' }}</p>
                </div>
                <div class="two" id="view-advisories-feature-summary">
                  <h3 id="view-advisories-feature-summary" title="{{ d?.description?.source_text }}">{{ d.cve }} | {{ d?.description?.source_text | charslice: 100 }}</h3>
                </div>
                <div id="view-advisories-feature-desc" class="three" title="{{ d?.description?.text ? d?.description?.text : d?.description?.impact }}">
                  {{ (d?.description?.text ? d?.description?.text : d?.description?.impact) | charslice: 150 }}
                </div>
              </div>
            </div>
            <div class="five " id="view-advisories-feature-badge">
              <owc-badge #badgeElement class="badge-element" *ngIf="d?.cwe">
                <span id="view-advisories-cwe-id" i18n="@@cwe-id">{{ d.cwe }}</span>
              </owc-badge>

              <owc-badge #badgeElement class="severity-badge-element" *ngIf="d?.basescore">
                <span id="basescore" i18n="@@basescore">{{ d?.basescore }}</span>
              </owc-badge>
            </div>
            <div class="create-ticket-btn four">
              <button id="advisories-view-details-btn-{{ i }}" one-secondary-button mat-raised-button (click)="this.viewDetails(d.cve, d.identifier)" i18n="@@view-details-btn">View details</button>
            </div>
          </owc-card>
        </ng-container>
      </div>
    </div>
    <div id="view-advisories-footer" slot="footer" *ngIf="advisoryData.length > 0">
      <owc-pagination id="view-advisories-pagination" [options]="rowsOptions" [page]="page" [rowsPerPage]="rowsPerPage" [total]="total" (pageChange)="pageChangeHandler($event.detail)">
        <div id="pagination-rows-count" slot="rows-per-page" i18n="@@rows-per-page">Rows per page</div>
        <div id="pagination-page-no">{{ page }} <span id="of-text" i18n="@@of"> of </span> {{ total }}</div>
      </owc-pagination>
    </div>
  </div>
  <div [style]="{ width: '5%' }"></div>
</div>
