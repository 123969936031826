import { ChangeDetectionStrategy, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Components } from '@one/web-components';
import { Router } from '@angular/router';
import { FeatureService } from '../../services/feature.service';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from '@dialog-eservices-enablement/angular-components';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../common/popup/popup.component';
import { environment } from 'projects/dialog/src/environments/environment';
import { Account } from '../../services/account';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'dl-app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class HomePageComponent implements OnInit, OnDestroy {
  public accounts: any;
  public tabValue: string;
  licensedLabs: ({ crmNumber: string; license: { SoC: { id: string }; NonSoC?: undefined }; is_lab_selected: boolean } | { crmNumber: string; license: { NonSoC: { id: string }; SoC?: undefined }; is_lab_selected: boolean })[];
  public constructor(private router: Router, private featureService: FeatureService, private authService: AuthService, public dialog: MatDialog) {}

  public isLoggedIn = this.authService.isLoggedIn;
  public selectedLab: Account[];
  public subs: Subscription[] = [];
  public env: string;
  public firewallData: any = {};
  public countryCode: string;
  public invisibleCount: number;
  public firewallLicense: number;
  public vulnerabilityLicence: number;
  public socLicence: number;
  public licenseExpired: number;
  public socAndCombinedLicense: number;
  public selectedLabIndex: number;
  public selectedLabsIndex: any[];
  public advisoriesLicense: number;
  public objectKeys: any;
  public multiple: boolean;
  public centericSelection: boolean;
  public selectedLabsData: any = [];
  private labSelectionChange = new Subject<object>();
  private readonly debounceTimeMs = 1000;

  @ViewChild('selectDropdownElement', { static: true }) public selectDropdownElement: Components.OwcSelectDropdown;
  @ViewChild('tabsElement', { static: true }) public tabsElement: Components.OwcTabs;
  @ViewChild('selectDropdownElement1', { static: true }) public selectDropdownElement1: Components.OwcSelectDropdown;

  public currentApplicationVersion = environment.appVersion;

  public ngOnInit(): void {
    this.selectedLabsIndex = [];
    this.objectKeys = Object.keys;
    this.selectedLab = [];
    this.centericSelection = false;
    this.selectedLabIndex = 0;
    this.invisibleCount = 0;
    localStorage.setItem('token', this.authService.userProfile.jwt);
    this.countryCode = this.authService?.userProfile?.countryCode.toUpperCase();
    this.featureService.countryCode.next(this.countryCode);
    this.subs.push(
      this.featureService.env.subscribe(env => {
        this.env = env;
      })
    );
    this.getAccounts();
    this.labSelectionChange.pipe(debounceTime(this.debounceTimeMs)).subscribe(() => {
      this.selectLab();
      this.resetFeatureService();
      // this.getLicenses();
      this.routeToSelctedLab();
    });
  }

  public onTabChange(tab: any): void {
    this.tabValue = tab;
    switch (tab) {
      case 'devices': {
        this.router.navigate(['app-enabler/laboratory']);
        break;
      }
      case 'overview': {
        this.router.navigate(['app-enabler/overview']);
        break;
      }
      case 'view-advisories': {
        this.router.navigate(['app-enabler/view-advisories']);
        break;
      }
    }
  }
  public getAccounts(): void {
    const contactId = `${this.authService.userProfile.contactId}`;
    let url = '';
    console.log('countryCode: ' + this.countryCode);
    // For running on local use below else comment it
    if (this.env !== '') {
      url = `${this.env}/${this.featureService.msUrl}/${this.countryCode}/users/${contactId}/`;
    } else {
      url = `/${this.featureService.msUrl}/CH/users/CON-0003431477/`;
    }

    this.accounts = [];
    this.selectedLabIndex = 0;
    this.subs.push(
      this.featureService.getAccounts(url).subscribe(
        response => {
          if (response?.status === 'SUCCESS' || response?.status === 'PARTIAL') {
            this.accounts = response?.response?.response?.content?.accounts;

            // Code to check if user has activated trial license then get stored selected lab
            const selectedLabId = localStorage.getItem('selectedLab');
            this.accounts.licensed.forEach((item, index) => {
              if (item.crmNumber === selectedLabId) {
                this.selectedLabIndex = index;
              }
              if (item.is_lab_selected) {
                this.centericSelection = true;
                this.selectedLabsIndex.push(index);
              }
            });
            this.featureService.laboratories.next(this.accounts);
          } else {
            // code to show message box
            this.featureService.notify(response, 'error');
          }

          this.tabValue = 'overview';
        },
        error => {
          console.log('getAccounts error: ' + error);
          this.featureService.notify(error?.error ? error.error : error, 'error');
        }
      )
    );
  }

  public selectViewOption(event: CustomEvent<number[]>): void {
    //console.log('selectOption', event.detail);
    const selection = event.detail[0];
    this.selectedLabIndex = 0;
    this.selectedLab = [];
    if (selection == 0) {
      this.centericSelection = false;
    } else if (selection == 1) {
      this.centericSelection = true;
    }
  }

  public selectChangeHandler(event: CustomEvent<number[]>): void {
    this.selectedLabIndex = -1;
    //this.selectedLabsIndex = [];
    localStorage.removeItem('selectedLab');
    if (this.centericSelection == false) {
      this.selectedLabIndex = event.detail[0];
      this.selectLab();
      this.resetFeatureService();
      this.getLicenses();
      this.routeToSelctedLab();
    } else {
      this.selectedLabsIndex = event.detail;
      this.labSelectionChange.next();
    }
  }

  public routeToSelctedLab(): void {
    // TODO: check use of below line
    //this.authService.userProfile.accountId = this.selectedLab[0].crmNumber;
    this.navigateBasedOnLicense();
    this.featureService.selectedLab.next(this.selectedLab);
    this.navigateBasedOnCurrentUrl();
  }

  public panelCloseHandler(event: CustomEvent<number[]>): void {
    console.log('panelCloseHandler', event.detail);
    if (this.centericSelection == true) {
      this.applyCentricView(this.selectedLabsData);
    }
  }

  public selectLab(): void {
    if (this.accounts?.licensed && this.accounts.licensed.length > this.selectedLabIndex) {
      this.selectLicensedLab();
    } else {
      this.selectNonLicensedLab();
    }
  }

  public selectLicensedLab(): void {
    let selectedSoCLabs = [];
    this.selectedLab = [];
    // Select only SoC license Labs and store them in selectedSoCLabs
    if (this.centericSelection == true) {
      this.accounts.licensed.forEach((item, index) => {
        if (Object.keys(item.license).indexOf('SoC') > -1) {
          selectedSoCLabs.push(item);
        }
      });

      this.selectedLabsData = [];
      selectedSoCLabs.forEach((lab, idex) => {
        lab.is_lab_selected = false;
        // selectedLabsIndex is collection of selected labs by user in centric view
        this.selectedLabsIndex.forEach((itm, i) => {
          if (idex === itm) {
            lab.is_lab_selected = true;
            this.selectedLab.push(lab); // selectedLab is collection of selected labs to show in UI
          }
        });
        
        // selectedLabsData is collection of data as per required format to send to backend to save selected labs
        this.selectedLabsData.push({ lab_id: lab.crmNumber, entitlement_id: lab.license['SoC'].id, is_lab_selected: lab.is_lab_selected });
      });
    } else {
      // Single lab selection mode
      this.accounts.licensed.forEach((item, index) => {
        if (index === this.selectedLabIndex) {
          // item.is_lab_selected = false;
          this.selectedLab.push(item);
        }
      });
    }
  }

  public selectNonLicensedLab(): void {
    this.selectedLab = [];
    this.accounts?.['non-licensed'].forEach((item, index) => {
      if (index === this.selectedLabIndex - this.accounts.licensed.length) {
        this.selectedLab.push(item);
      }
    });
  }

  public resetFeatureService(): void {
    this.featureService.paginatedDevices.next([]);
    this.featureService.devicePageParams.next({ page: 1, rowsPerPage: 10, total: 1, rexisDevicesCount: 0, s3DevicesCount: 0 });
    this.featureService.invisibleCount.next(0);
    this.featureService.firewallData.next({});
  }

  public navigateBasedOnLicense(): void {
    if (this.selectedLab.length > 0 && this.licenseExpired === 1) {
      this.tabValue = 'overview';
      this.router.navigate(['app-enabler/overview']);
    } else if (this.socLicence || this.firewallLicense) {
      const labIds = this.selectedLab.map(lab => lab.crmNumber);
      this.getFirewallStatus(labIds, false);
    } else if (this.centericSelection === true) {
      const labIds = this.selectedLab.map(lab => lab.crmNumber);
      labIds && labIds.length > 0 ? this.getFirewallStatus(labIds, false) : '';
    }
  }

  public navigateBasedOnCurrentUrl(): void {
    if (!this.router.url) {
      console.error('Router URL is undefined.');
      return;
    }
    const currentUrl = this.router.url;

    if (currentUrl.indexOf('overview') !== -1 || currentUrl.indexOf('laboratory') !== -1) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
    } else {
      this.router.navigate(['app-enabler/laboratory']);
    }
  }

  public getLicenses(): void {
    if (this.centericSelection == false) {
      this.licenseExpired = Object.keys(this.selectedLab[0]?.license).length === 0 || this.selectedLab[0]?.license?.expired === true ? 1 : 0;
      this.firewallLicense = this.licenseExpired === 0 && this.selectedLab[0]?.license?.Firewall?.expired === false ? 1 : 0;
      this.advisoriesLicense = this.licenseExpired === 0 && this.selectedLab[0]?.license?.Advisories?.expired === false ? 1 : 0;
      this.socAndCombinedLicense = this.licenseExpired === 0 && this.selectedLab[0]?.license?.SoC?.expired === false ? 1 : 0;
      this.vulnerabilityLicence = this.licenseExpired === 0 && this.selectedLab[0]?.license?.Vulnerabilities?.expired === false ? 1 : 0;
      this.socLicence = this.licenseExpired === 0 && ((this.vulnerabilityLicence === 1 && this.firewallLicense === 1) || this.socAndCombinedLicense === 1) ? 1 : 0;
    }
  }

  public openErrorPopup(): void {
    const data = { title: $localize`:@@access-denied:'Access denied: Non-licensed lab'`, body: $localize`:@@access-denied-msg:'You don\'t have access to view details of this lab as this lab is not licensed under the agreement.'`, primaryBtn: $localize`:@@close-btn:Close`, secondaryBtn: $localize`:@@cancel-btn:Cancel` };
    this.dialog.open(PopupComponent, { data });
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public applyCentricView(selectedLabsData): void {
    // if(this.centericSelection && this.selectedLabsIndex.length === 0){
    //   return;
    // }
    let url = '';
    const contactId = `${this.authService.userProfile.contactId}`;
    if (this.env !== '') {
      url = `${this.env}/${this.featureService.msUrl}/${this.countryCode}/users/${contactId}/`;
    } else {
      url = `/${this.featureService.msUrl}/CH/users/CON-0003431477/`;
    }
    let data = { labs: selectedLabsData };

    this.subs.push(
      this.featureService.applyCentricView(url, data).subscribe(
        response => {
          if (response?.status === 'SUCCESS' || response?.status === 'PARTIAL') {
            // this.featureService.notify(response, 'success');
          } else {
            // code to show message box
            this.featureService.notify(response, 'error');
          }
        },
        error => {
          console.log('getAccounts error: ' + error);
          this.featureService.notify(error?.error ? error.error : error, 'error');
        }
      )
    );
  }

  public getFirewallStatus(labIds: any, flag: boolean): void {
    if (this.centericSelection && this.selectedLabsIndex.length === 0) {
      return;
    }
    const url = `${this.env}/${this.featureService.fnUrl}/${this.countryCode}/firewall/status/`;
    this.firewallData = {};
    this.subs.push(
      this.featureService.getFirewallStatus(url, labIds, flag).subscribe(
        response => {
          if (response?.status === 'SUCCESS' || response?.status === 'PARTIAL') {
            this.firewallData = response?.response?.data; // Main firewall status data
            this.featureService.firewallData.next(this.firewallData);
          } else {
            this.firewallData = {};
            this.featureService.notify(response, 'error');
            this.featureService.firewallData.next({});
          }
        },
        error => {
          console.log('getFirewallStatus: ' + error);
          this.featureService.notify(error?.error ? error.error : error, 'error');
          this.featureService.firewallData.next({});
        }
      )
    );
  }
}
