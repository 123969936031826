import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { Components } from '@one/web-components';
import { Router } from '@angular/router';
import { FeatureService } from '../../services/feature.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import * as $ from 'jquery';

interface Account {
  account_name: string;
  account_number: string;
  account_primary: string;
  crmNumber: string;
  erpNumber: string;
  sales_org: string;
  license: {
    Firewall: { id: number; expired: boolean; expiry_date: number };
    Vulnerabilities: { id: number; expired: boolean; expiry_date: number };
    expired: boolean;
    Advisories: { id: number; expired: boolean; expiry_date: number };
    SoC: { id: number; expired: boolean; expiry_date: number };
  };
}

@Component({
  selector: 'dl-view-advisories',
  templateUrl: './view-advisories.component.html',
  styleUrls: ['./view-advisories.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ViewAdvisoriesComponent implements OnInit, OnDestroy, AfterViewInit {
  public advisoryData: any;
  public page: number;
  public total: number;
  public rowsOptions: any;
  public rowsPerPage: number;
  public subs: Subscription[] = [];
  public countryCode: string;
  public env: string;
  public searchTxt: string;
  public startDate: Date;
  public endDate: Date;
  public redioFilterSelected: string;
  public selectedLab: any;
  private searchSubject = new Subject<string>();
  private pageChange = new Subject<object>();
  private readonly debounceTimeMs = 1000; // Set the debounce time (in milliseconds)
  public advanceFilterOption: any;
  public selectedCategories: string[];
  public severityStart: number;
  public severityEnd: number;
  public selectedProduct: string[];
  public selectedVendor: string[];
  public modifiedStartDate: any;
  public modifiedEndDate: any;
  public publishedStartDate: any;
  public publishedEndDate: any;
  public sortBy: string[];
  public today: Date;
  public selectedSeverity: any;
  public keepData: boolean;
  public selectedDays: any;
  public searched: boolean;
  public pageParams: any;
  public datePickerPlaceholder = $localize`:@@datepicker-placeholder:Please select valid date(s)`;

  @ViewChild('paginationElement', { static: true }) public paginationElement: Components.OwcPagination;
  @ViewChild('selectDropdownElement', { static: true }) public selectDropdownElement: Components.OwcSelectDropdown;
  @ViewChild('datepickerElement', { static: true }) public datepickerElement: Components.OwcDatepicker;
  @ViewChild('categoryType') public categoryType: Components.OwcSelectDropdown;
  @ViewChild('vendor') public vendor: Components.OwcSelectDropdown;
  @ViewChild('severity') public severity: Components.OwcSelectDropdown;
  @ViewChild('product') public product: Components.OwcSelectDropdown;
  @ViewChild('publishedDate') public publishedDate: Components.OwcDatepicker;
  @ViewChild('modifiedDate') public modifiedDate: Components.OwcDatepicker;

  public constructor(private featureService: FeatureService, private router: Router, private ref: ChangeDetectorRef, private renderer: Renderer2) {}
  public ngOnInit(): void {
    localStorage.removeItem('selectedLab');
    this.page = 1;
    this.searched = false;
    this.rowsPerPage = 10;
    this.total = 1;
    this.rowsOptions = [10, 20, 25];
    this.countryCode = '';
    this.searchTxt = '';
    this.sortBy = ['publish_date'];
    this.endDate = new Date();
    this.advanceFilterOption = {};
    this.modifiedStartDate = '';
    this.publishedStartDate = '';
    this.publishedEndDate = '';
    this.modifiedEndDate = '';
    this.selectedProduct = [];
    this.selectedVendor = [];
    this.selectedCategories = [];
    this.severityStart = 0;
    this.severityEnd = 10;
    this.today = new Date();
    this.keepData = false;

    this.getLastWeeksDate(7);
    this.subs.push(
      this.featureService.env.subscribe(env => {
        this.env = env;
      })
    );
    this.subs.push(
      this.featureService.countryCode.subscribe(countryCode => {
        this.countryCode = countryCode;
      })
    );
    this.subs.push(
      this.featureService.selectedLab.pipe(take(1)).subscribe((selAccountData: Account[]) => {
        if (selAccountData && selAccountData.length > 0) {
          this.selectedLab = selAccountData[0];
        }
      })
    );

    this.searchSubject.pipe(debounceTime(this.debounceTimeMs)).subscribe(searchValue => {
      this.page = 1;
      const parameters = { page: this.page, rowsPerPage: this.rowsPerPage, search: searchValue };
      this.getVulnerabilityAdvisories(parameters);
    });

    this.pageChange.pipe(debounceTime(this.debounceTimeMs)).subscribe(() => {
      this.featureService.vulnAdvisoryPagination.next({ page: this.page, rowsPerPage: this.rowsPerPage, total: this.total });
      if (this.searched) {
        this.pageParams = { page: this.page, rowsPerPage: this.rowsPerPage, search: this.searchTxt };
        this.getVulnerabilityAdvisories(this.pageParams);
      } else if (this.redioFilterSelected === 'basic') {
        this.pageParams = { page: this.page, rowsPerPage: this.rowsPerPage, search: this.searchTxt, startDate: Math.floor(new Date(this.startDate).getTime()), endDate: Math.floor(new Date(this.endDate).getTime()), sortBy: this.sortBy[0] };
        this.getVulnerabilityAdvisories(this.pageParams);
      } else {
        this.applyAdvanceFilter(this.page);
      }
    });

    this.subs.push(
      this.featureService.vulnAdvisoryDetails.subscribe(allDetails => {
        this.redioFilterSelected = allDetails?.redioFilterSelected;
        this.advanceFilterOption = allDetails?.advanceFilterOption;
        this.selectedCategories = allDetails?.selectedCategories;
        this.selectedProduct = allDetails?.selectedProduct;
        this.selectedVendor = allDetails?.selectedVendor;
        this.selectedSeverity = allDetails?.selectedSeverity;
        this.sortBy = allDetails?.sortBy;
        this.selectedDays = allDetails?.selectedDays;
        this.severityStart = allDetails?.severityStart;
        this.severityEnd = allDetails?.severityEnd;
        this.startDate = allDetails?.startDate;
        this.endDate = allDetails?.endDate;
        this.searchTxt = allDetails?.searchTxt;
        this.searched = allDetails?.searched;
      })
    );
    this.subs.push(
      this.featureService.advisoryData.subscribe(allDetails => {
        this.advisoryData = allDetails;
      })
    );
    this.subs.push(
      this.featureService.vulnAdvisoryPagination.subscribe(allDetails => {
        this.page = allDetails?.page;
        this.rowsPerPage = allDetails?.rowsPerPage;
        this.total = allDetails?.total;
      })
    );

    const params = { page: this.page, rowsPerPage: this.rowsPerPage, search: '', startDate: Math.floor(new Date(this.startDate).getTime()), endDate: Math.floor(new Date(this.endDate).getTime()), sortBy: this.sortBy[0] };
    if (!('categories' in this.advanceFilterOption)) {
      this.getVulnerabilityAdvisories(params);
    }
    if (!('categories' in this.advanceFilterOption)) {
      this.getVulnerabilityFilterData();
    }
  }

  public ngAfterViewInit(): void {
    let controls;
    controls = $('#tabsElement').find('owc-tab');
    $(controls[2]).trigger('click');
    this.ref.markForCheck();
    this.subs.push(
      this.featureService.vulnAdvisoryDetails.subscribe(allDetails => {
        if (allDetails?.publishedStartDate && allDetails?.publishedEndDate) {
          this.publishedDate.reset([new Date(allDetails?.publishedStartDate), new Date(allDetails?.publishedEndDate)]);
        }
        if (allDetails?.modifiedStartDate && allDetails?.modifiedEndDate) {
          this.modifiedDate.reset([new Date(allDetails?.modifiedStartDate), new Date(allDetails?.modifiedEndDate)]);
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    if (!this.keepData) {
      this.featureService.vulnAdvisoryDetails.next({ selectedDays: 0, searchTxt: '', searched: false, startDate: new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 7), endDate: this.today, sortBy: ['publish_date'], advanceFilterOption: {}, redioFilterSelected: 'basic', selectedCategories: [], selectedProduct: [], selectedVendor: [], modifiedStartDate: '', publishedStartDate: '', publishedEndDate: '', modifiedEndDate: '', severityStart: 0, severityEnd: 10, selectedSeverity: 5 });
      this.featureService.advisoryData.next([]);
      this.featureService.vulnAdvisoryPagination.next({ page: 1, rowsPerPage: 10, total: 1 });
    }
    const pageParams = { page: 1, rowsPerPage: 10, total: 1, rexisDevicesCount: 0, s3DevicesCount: 0 };
    this.featureService.devicePageParams.next(pageParams);
  }

  public Search(): void {
    this.clearFilters();
    this.searched = true;
    this.featureService.vulnAdvisoryDetails.next({ selectedDays: this.selectedDays, searchTxt: this.searchTxt, searched: this.searched, startDate: this.startDate, endDate: this.endDate, sortBy: this.sortBy, advanceFilterOption: this.advanceFilterOption, redioFilterSelected: this.redioFilterSelected, selectedCategories: this.selectedCategories, selectedProduct: this.selectedProduct, selectedVendor: this.selectedVendor, modifiedStartDate: this.modifiedStartDate, publishedStartDate: this.publishedStartDate, publishedEndDate: this.publishedEndDate, modifiedEndDate: this.modifiedEndDate, severityStart: this.severityStart, severityEnd: this.severityEnd, selectedSeverity: this.selectedSeverity });
    this.searchSubject.next(this.searchTxt);
  }

  public clearFilters(): void {
    this.selectedCategories = [];
    this.categoryType.reset();
    this.selectedVendor = [];
    this.vendor.reset();
    this.selectedProduct = [];
    this.product.reset();
    this.selectedSeverity = 5;
    this.severity.reset();
    this.severityStart = 0;
    this.severityEnd = 10;
    this.publishedDate.reset();
    this.modifiedDate.reset();
  }

  public selectChangeHandler(event: CustomEvent<number[]>): void {
    const selectedOption = event.detail[0];
    this.selectedDays = selectedOption;
    this.getDaysByOption(selectedOption);
  }

  public getDaysInLastXMonths(x: number): number {
    const today = new Date(); // Current date

    let totalDays = 0;
    for (let i = 0; i < x; i++) {
      const currentDate = new Date(today.getFullYear(), today.getMonth() - i, 0);
      const daysInMonth = currentDate.getDate();
      totalDays += daysInMonth;
    }

    return totalDays;
  }

  public getDaysByOption(selectedOption: number): void {
    let days = 0;
    if (selectedOption === 0) {
      days = 7;
    } else if (selectedOption === 1) {
      days = 15;
    } else if (selectedOption === 2) {
      days = this.getDaysInLastXMonths(1);
    } else if (selectedOption === 3) {
      days = this.getDaysInLastXMonths(3);
    } else if (selectedOption === 4) {
      days = this.getDaysInLastXMonths(6);
    }
    this.getLastWeeksDate(days);
  }

  public onCategoryPanelClose(value: any): void {
    this.selectedCategories = value;
  }

  public onVendorPanelClose(value: any): void {
    this.selectedVendor = value;
  }

  public onProductPanelClose(value: any): void {
    this.selectedProduct = value;
  }

  public sortByValue(value: string[]): void {
    this.sortBy = value;
  }

  public selectChangeSeverityHandler(event: CustomEvent<number[]>): void {
    const selectedOption = event.detail[0];
    this.selectedSeverity = selectedOption;
    if (selectedOption === 0) {
      this.severityStart = 0;
      this.severityEnd = 10;
    } else if (selectedOption === 1) {
      this.severityStart = 0.1;
      this.severityEnd = 3.9;
    } else if (selectedOption === 2) {
      this.severityStart = 4;
      this.severityEnd = 6.9;
    } else if (selectedOption === 3) {
      this.severityStart = 7;
      this.severityEnd = 8.9;
    } else if (selectedOption === 4) {
      this.severityStart = 9;
      this.severityEnd = 10;
    }
  }

  public onPublishedDateValueChange(event: any): void {
    if (!event.detail) {
      this.publishedStartDate = '';
      this.publishedEndDate = '';
    } else if (event.detail.length === 2) {
      this.publishedStartDate = event.detail[0];
      this.publishedEndDate = event.detail[1];
    }
  }
  public onDatePickerPanelOpen(event: any): void {
    const quickSelectionsElements = document.querySelectorAll('.quick-selections');
    quickSelectionsElements.forEach(element => {
      this.renderer.setStyle(element, 'display', 'none');
    });
  }

  public onModifiedDateValueChange(event: any): void {
    if (!event.detail) {
      this.modifiedStartDate = '';
      this.modifiedEndDate = '';
    } else if (event.detail.length === 2) {
      this.modifiedStartDate = event.detail[0];
      this.modifiedEndDate = event.detail[1];
    }
  }

  public applyFilter(): void {
    this.searched = false;
    this.page = 1;
    this.getDaysByOption(this.selectedDays);
    const params = { sortBy: this.sortBy[0], page: 1, rowsPerPage: this.rowsPerPage, total: 1, search: this.searchTxt, startDate: Math.floor(new Date(this.startDate).getTime()), endDate: Math.floor(new Date(this.endDate).getTime()) };
    this.clearFilters();
    this.getVulnerabilityAdvisories(params);
    this.publishedStartDate = this.startDate;
    this.publishedEndDate = this.endDate;
    this.featureService.vulnAdvisoryDetails.next({ selectedDays: this.selectedDays, searchTxt: this.searchTxt, searched: this.searched, startDate: this.startDate, endDate: this.endDate, sortBy: this.sortBy, advanceFilterOption: this.advanceFilterOption, redioFilterSelected: this.redioFilterSelected, selectedCategories: this.selectedCategories, selectedProduct: this.selectedProduct, selectedVendor: this.selectedVendor, modifiedStartDate: this.modifiedStartDate, publishedStartDate: this.publishedStartDate, publishedEndDate: this.publishedEndDate, modifiedEndDate: this.modifiedEndDate, severityStart: this.severityStart, severityEnd: this.severityEnd, selectedSeverity: this.selectedSeverity });
  }

  public applyAdvanceFilter(page: number = 1): void {
    this.searched = false;
    this.page = page;

    const params = { sortBy: this.sortBy[0], page: this.page, rowsPerPage: this.rowsPerPage, search: this.searchTxt, publishedStartDate: this.publishedStartDate, publishedEndDate: this.publishedEndDate, modifiedStartDate: this.modifiedStartDate, modifiedEndDate: this.modifiedEndDate, products: this.selectedProduct, vendors: this.selectedVendor, categories: this.selectedCategories, severityStart: this.severityStart, severityEnd: this.severityEnd };
    if (this.modifiedStartDate !== '' && this.modifiedEndDate !== '') {
      params.modifiedStartDate = Math.floor(new Date(this.modifiedStartDate).getTime());
      params.modifiedEndDate = Math.floor(new Date(this.modifiedEndDate).getTime());
    }
    if (this.publishedStartDate !== '' && this.publishedEndDate !== '') {
      params.publishedStartDate = Math.floor(new Date(this.publishedStartDate).getTime());
      params.publishedEndDate = Math.floor(new Date(this.publishedEndDate).getTime());
    }
    if (this.selectedProduct.length === this.advanceFilterOption?.products.length) {
      params.products = [];
    }
    if (this.selectedVendor.length === this.advanceFilterOption?.vendors.length) {
      params.vendors = [];
    }
    if (this.selectedCategories.length === this.advanceFilterOption?.categories.length) {
      params.categories = [];
    }
    this.getVulnerabilityAdvisories(params);
    this.startDate = this.publishedStartDate;
    this.endDate = this.publishedEndDate;
    this.featureService.vulnAdvisoryDetails.next({ selectedDays: this.selectedDays, searchTxt: this.searchTxt, searched: this.searched, startDate: this.startDate, endDate: this.endDate, sortBy: this.sortBy, advanceFilterOption: this.advanceFilterOption, redioFilterSelected: this.redioFilterSelected, selectedCategories: this.selectedCategories, selectedProduct: this.selectedProduct, selectedVendor: this.selectedVendor, modifiedStartDate: this.modifiedStartDate, publishedStartDate: this.publishedStartDate, publishedEndDate: this.publishedEndDate, modifiedEndDate: this.modifiedEndDate, severityStart: this.severityStart, severityEnd: this.severityEnd, selectedSeverity: this.selectedSeverity });
  }

  public onRadioInputChange(txt: string): void {
    if (txt === 'advanced') {
      setTimeout(() => {
        // Perform actions after waiting for the HTML to render
        if (!(this.publishedStartDate && this.publishedEndDate) && this.startDate && this.endDate) {
          this.publishedDate.reset([this.startDate, this.endDate]);
        }
      }, 300);
    }
  }

  public viewDetails(cveid: string, identifier: string): void {
    this.keepData = true;
    this.router.navigate(['app-enabler/view-advisories-detail/' + cveid], { queryParams: { identifier } });
  }

  public getLastWeeksDate(days: number): void {
    const now = new Date();
    this.endDate = now;
    this.startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - days);
  }

  public pageChangeHandler(event: any): void {
    /* condition added to avoid multiple requests on page load and on next/pre arrow click   */
    if (event?.rowsPerPage === 1) {
      event.rowsPerPage = 10;
    }
    if (this.page !== event?.page || this.rowsPerPage !== event?.rowsPerPage) {
      this.page = event?.page;
      this.rowsPerPage = event?.rowsPerPage;
      this.pageChange.next(this.pageParams);
    }
  }

  public getVulnerabilityAdvisories(event: any): void {
    const url = `${this.env}/${this.featureService.vmsUrl}/${this.countryCode}/${this.selectedLab.crmNumber}/vulnerabilities/`;
    event.search = this.searchTxt;
    event.page = event.page === 0 ? 0 : event.page - 1;
    event.type = 'list';
    this.subs.push(
      this.featureService.getVulnerabilityAdvisories(url, event).subscribe(
        response => {
          if (response?.status === 'SUCCESS' || response?.status === 'PARTIAL') {
            this.advisoryData = response?.response?.data;
            this.featureService.advisoryData.next(this.advisoryData);
            this.total = Math.ceil(response?.response.filtered / this.rowsPerPage);
            if (this.total === 0) {
              this.total = 1;
            }

            this.featureService.vulnAdvisoryPagination.next({ page: this.page, rowsPerPage: this.rowsPerPage, total: this.total });
          } else {
            // code to show message box
            this.featureService.notify(response, 'error');
          }
        },
        error => {
          console.log('getVulnerabilityAdvisories error: ' + error);
          this.featureService.notify(error?.error ? error.error : error, 'error');
        }
      )
    );
  }

  public getVulnerabilityFilterData(): void {
    const url = `${this.env}/${this.featureService.vmsUrl}/${this.countryCode}/${this.selectedLab.crmNumber}/vulnerabilities/`;
    this.subs.push(
      this.featureService.getVulnerabilityFilterData(url).subscribe(
        response => {
          if (response?.status === 'SUCCESS' || response?.status === 'PARTIAL') {
            this.advanceFilterOption = response?.response;
          } else {
            // code to show message box
            this.featureService.notify(response, 'error');
          }
        },
        error => {
          console.log('getVulnerabilityFilterData error: ' + error);
          this.featureService.notify(error?.error ? error.error : error, 'error');
        }
      )
    );
  }
}
