import { ModuleWithProviders, NgModule } from '@angular/core';
import { LoginDialogModule, RegionDialogModule } from '@dialog-eservices-enablement/angular-components';
import { FooterModule } from '@dialog-eservices-enablement/angular-components';
import { HeaderModule } from '@dialog-eservices-enablement/angular-components';
import { ENVIRONMENT } from '@dialog-eservices-enablement/angular-components';
import { FeatureFlagModule } from '@dialog-eservices-enablement/angular-components';
import { loadingInterceptorProvider } from '@dialog-eservices-enablement/angular-components';
import { LoadingBarModule } from '@dialog-eservices-enablement/angular-components';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { appInitializerProvider } from './app-initializer/app-initializer.service';
import { environment } from '../../environments/environment';
import { APP_BASE_HREF, CommonModule, PlatformLocation } from '@angular/common';
import { OneDialogModule } from '@one/angular-kit/modal';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { OneEditableDropdownModule } from '@one/angular-kit/form';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { OneCardModule } from '@one/angular-kit/layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [],
  exports: [LoginDialogModule, FooterModule, HeaderModule, FeatureFlagModule, LoadingBarModule, RegionDialogModule],  
  imports: [
    HttpClientModule,
    HttpClientJsonpModule, 
    LoginDialogModule,
   RegionDialogModule,
    FooterModule,
    HeaderModule,
    FeatureFlagModule,
    LoadingBarModule,
    CommonModule,
    MatCardModule,
    OneCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatInputModule,
    OneDialogModule,
    ReactiveFormsModule,
    MatDialogModule,
    OneEditableDropdownModule,
    MatIconModule,
    RouterModule
  ]

})
export class CoreModule {
  public static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: ENVIRONMENT, useValue: environment },
        {
          provide: APP_BASE_HREF,
          useFactory: (platformLocation: PlatformLocation) => platformLocation.getBaseHrefFromDOM(),
          deps: [PlatformLocation]
        },
        appInitializerProvider,
        loadingInterceptorProvider
      ]
    };
  }
}
