
export  const messages= [
		{"id":"200","msg":$localize`:@@200:Operation succeeded`},
		{"id":"206","msg": $localize`:@@206:Not sufficient Data`},
		{"id":"301","msg": $localize`:@@301:Product not found.`},
		{"id":"400","msg":$localize`:@@400:No response`},
		{"id":"404","msg":$localize`:@@404:No response`},
		{"id":"405","msg":$localize`:@@405:No response`},
		{"id":"422","msg":$localize`:@@422:No response`},
		{"id":"500","msg": $localize`:@@500:Internal server error`},
		{"id":"501","msg": $localize`:@@501:No response`},
		{"id":"502","msg": $localize`:@@502:No response`},
		{"id":"503","msg": $localize`:@@503:No response`},
		{"id":"504","msg": $localize`:@@504:Gateway timeout`},

		{"id":"SS_HTTP_301","msg":$localize`:@@SS_HTTP_301:No data found in Security suite backend.`},
		{"id":"SS_HTTP_400","msg":$localize`:@@SS_HTTP_400:No response from Security suite backend.`},
		{"id":"SS_HTTP_404","msg":$localize`:@@SS_HTTP_404:No response from Security suite backend.`},
		{"id":"SS_HTTP_422","msg":$localize`:@@SS_HTTP_422:No response from Security suite backend.`},
		{"id":"SS_HTTP_405","msg":$localize`:@@SS_HTTP_405:No response from Security suite backend.`},
		{"id":"SS_HTTP_500","msg":$localize`:@@SS_HTTP_500:Internal server error from Security suite.`},
		{"id":"SS_HTTP_501","msg":$localize`:@@SS_HTTP_501:No response from Security suite backend.`},
		{"id":"SS_HTTP_502","msg":$localize`:@@SS_HTTP_502:No response from Security suite backend.`},
		{"id":"SS_HTTP_503","msg":$localize`:@@SS_HTTP_503:No response from Security suite backend.`},
		{"id":"SS_HTTP_504","msg":$localize`:@@SS_HTTP_504:Gateway timeout from Security suite`},

		{"id":"SS_VMS_301","msg":$localize`:@@SS_VMS_301:Product not found in VMS`},
		{"id":"SS_VMS_400","msg":$localize`:@@SS_VMS_400:No response from VMS backend.`},
		{"id":"SS_VMS_404","msg":$localize`:@@SS_VMS_404:No response from VMS backend.`},
		{"id":"SS_VMS_422","msg":$localize`:@@SS_VMS_422:No response from VMS backend.`},
		{"id":"SS_VMS_405","msg":$localize`:@@SS_VMS_405:No response from VMS backend.`},
		{"id":"SS_VMS_500","msg":$localize`:@@SS_VMS_500:Internal server error from VMS`},
		{"id":"SS_VMS_501","msg":$localize`:@@SS_VMS_501:No response from VMS backend.`},
		{"id":"SS_VMS_502","msg":$localize`:@@SS_VMS_502:No response from VMS backend.`},
		{"id":"SS_VMS_503","msg":$localize`:@@SS_VMS_503:No response from VMS backend.`},
		{"id":"SS_VMS_504","msg":$localize`:@@SS_VMS_504:Gateway timeout from VMS`},

		{"id":"SS_MS_301","msg":$localize`:@@SS_MS_301:Product not found in Rexis.`},
		{"id":"SS_MS_400","msg":$localize`:@@SS_MS_400:No response from Rexis.`},
		{"id":"SS_MS_404","msg":$localize`:@@SS_MS_404:No response from Rexis.`},
		{"id":"SS_MS_422","msg":$localize`:@@SS_MS_422:No response from Rexis.`},
		{"id":"SS_MS_405","msg":$localize`:@@SS_MS_405:No response from Rexis.`},
		{"id":"SS_MS_500","msg":$localize`:@@SS_MS_500:Internal server error from Rexis`},
		{"id":"SS_MS_501","msg":$localize`:@@SS_MS_501:No response from Rexis.`},
		{"id":"SS_MS_502","msg":$localize`:@@SS_MS_502:No response from Rexis.`},
		{"id":"SS_MS_503","msg":$localize`:@@SS_MS_503:No response from Rexis.`},
		{"id":"SS_MS_504","msg":$localize`:@@SS_MS_504:Gateway timeout from Rexis`},

		{"id":"SS_FN_301","msg":$localize`:@@SS_FN_301:No data found in backend management.`},
		{"id":"SS_FN_400","msg":$localize`:@@SS_FN_400:No response from backend management.`},
		{"id":"SS_FN_404","msg":$localize`:@@SS_FN_404:No response from backend management.`},
		{"id":"SS_FN_422","msg":$localize`:@@SS_FN_422:No response from backend management.`},
		{"id":"SS_FN_405","msg":$localize`:@@SS_FN_405:No response from backend management.`},
		{"id":"SS_FN_500","msg":$localize`:@@SS_FN_500:Internal server error in backend management.`},
		{"id":"SS_FN_501","msg":$localize`:@@SS_FN_501:No response from backend management.`},
		{"id":"SS_FN_502","msg":$localize`:@@SS_FN_502:No response from backend management.`},
		{"id":"SS_FN_503","msg":$localize`:@@SS_FN_503:No response from backend management.`},
		{"id":"SS_FN_504","msg":$localize`:@@SS_FN_504:Gateway timeout from backend management`},
		{"id":"SS_FN_REPORT_NOT_CONFIGURED","msg":$localize`:@@SS_FN_REPORT_NOT_CONFIGURED:Report is not configured in backend management.`},
		{"id":"ENTITLEMENT_NOT_FOUND","msg":$localize`:@@ENTITLEMENT_NOT_FOUND:License not found`},
		{"id":"LICENSE_NOT_FOUND","msg":$localize`:@@LICENSE_NOT_FOUND:License not found`},
		{"id":"SS_SOC_LICENSE_EXPIRED","msg":$localize`:@@SS_SOC_LICENSE_EXPIRED:SoC license expired in Security suite backend.`},
		{"id":"SS_SOC_LICENSE_NOT_FOUND","msg":$localize`:@@SS_SOC_LICENSE_NOT_FOUND:SoC license not found in Security suite backend.`},
		{"id":"SS_SOC_LICENSE_FOUND","msg":$localize`:@@SS_SOC_LICENSE_FOUND:SoC license is active in Security suite backend.`},
		{"id":"SS_VULNERABILITIES_LICENSE_EXPIRED","msg":$localize`:@@SS_VULNERABILITIES_LICENSE_EXPIRED:Vulnerabilities license expired in Security suite backend.`},
		{"id":"SS_VULNERABILITIES_LICENSE_NOT_FOUND","msg":$localize`:@@SS_VULNERABILITIES_LICENSE_NOT_FOUND:Vulnerabilities license not found in Security suite backend.`},
		{"id":"SS_VULNERABILITIES_LICENSE_FOUND","msg":$localize`:@@SS_VULNERABILITIES_LICENSE_FOUND:Vulnerabilities license is active in Security suite backend.`},
		{"id":"SS_FIREWALL_LICENSE_EXPIRED","msg":$localize`:@@SS_FIREWALL_LICENSE_EXPIRED:Firewall license expired in Security suite backend.`},
		{"id":"SS_FIREWALL_LICENSE_NOT_FOUND","msg":$localize`:@@SS_FIREWALL_LICENSE_NOT_FOUND:Firewall license not found in Security suite backend.`},
		{"id":"SS_FIREWALL_LICENSE_FOUND","msg":$localize`:@@SS_FIREWALL_LICENSE_FOUND:Firewall license is active in Security suite backend.`},
		{"id":"SS_LC_500","msg":$localize`:@@SS_FN_500:Internal server error in backend management.`},
		{"id":"SS_PRODUCTS_NOT_FOUND","msg":$localize`:@@:SS_PRODUCTS_NOT_FOUND:Product not found in Software distribution services.`},
		{"id":"","msg":$localize`:@@:`},
		{"id":"","msg":$localize`:@@:`},
		{"id":"","msg":$localize`:@@:`},
		{"id":"","msg":$localize`:@@:`},
		{"id":"","msg":$localize`:@@:`}
	]
